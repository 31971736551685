import {Carousel} from 'antd';
import React, { useEffect, useState } from 'react';
import './carousel.scss';
import { getEnterpriseLogoList } from "../../../api"
const CarouselPage: React.FC = () => {
    const [list, setList] = useState<any>([]);
    const getEnterpriseLogoListFun = async () => {
        const ctx: any = await getEnterpriseLogoList({ limit: 270 });
        const enterprise_logo = ctx.data.enterprise_logo;
        setList(enterprise_logo)
    }
    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: 'linear',
    };
    useEffect(() => {
        getEnterpriseLogoListFun()
    }, []);

    return (
        <Carousel {...settings} id={'carousel_page8'}>
            {list.map((it:any, index:any) => {
                return (
                    <div key={index} className={'carousel_box'}>
                        <div className={'img_box'}>
                            <img src={it} alt={it}/>
                        </div>
                        {/* <div className={'title'}>{it}</div> */}
                    </div>
                );
            })}
        </Carousel>
    );
};

export default CarouselPage;
