const chart1 = () => {
    return {
        animationDuration: 3000, // 动画时间
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: [
            {
                type: 'category',
                data: ["11月","12月","1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月" ],
                axisLine: {
                    show: true,
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '今年/往年',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            },
            {
                type: 'value',
                name: '',
                splitLine: { // 分隔线
                    show: false,
                },
            },
            {
                type: 'value',
                name: '增幅',
                axisLine: {
                    show: true,
                },
            }
        ],
        series: [
            {
                name: '今年',
                type: 'bar',
                data: [30000, 50000, 80000, 140000, 130000, 200000, 210000, 230000, 280000, 300000, 320000],
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
            {
                name: '往年',
                type: 'bar',
                data: [20000, 50000, 80000, 110000, 140000, 170000, 200000, 230000, 260000, 290000, 310000],
                barWidth: 18, // 柱状图宽度
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.3)',
                },
            },
            {
                name: '增幅',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: [0.1, 0.2, 0.3, 0.2, 0.4, 0.6, 0.7, 0.5, 0.8, 1, 1.1],
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.8)',
                }
            }
        ]
    };
};
export default chart1
