import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as echarts from "echarts";
import Page1 from "./pages/page1/Page1";
import Page2 from "./pages/page2/Page2";
import Page3 from "./pages/page3/Page3";
import Page4 from "./pages/page4/Page4";
import Page5 from "./pages/page5/Page5";
import Page6 from "./pages/page6/Page6";
import Page7 from "./pages/page7/Page7";
import Page8 from "./pages/page8/Page8";
import Page9 from "./pages/page9/Page9";
import Page10 from "./pages/page10/Page10";
import PageTest from "./pages/page_test/Page_test";
import PageDrill1 from "./pages/page_drill_1/PageDrill1";
import PageDrill2 from "./pages/page_drill_2/PageDrill2";
import PageDrill3 from "./pages/page_drill_3/PageDrill3";
import PageDrill4 from "./pages/page_drill_4/PageDrill4";
import PageDrill5 from "./pages/page_drill_5/PageDrill5";
import PageDrill6 from "./pages/page_drill_6/PageDrill6";
const colorPalette = [
    '#d87c7c',
    '#919e8b',
    '#d7ab82',
    '#6e7074',
    '#61a0a8',
    '#efa18d',
    '#787464',
    '#cc7e63',
    '#724e58',
    '#4b565b'
];
echarts.registerTheme('vintage', {
    color: colorPalette,
    graph: {
        color: colorPalette
    }
});

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                {/*<App width={1920} height={1080}/>*/}
                <Routes>
                    <Route path="/" element={<Page1 width={1920} height={1080}/>}/>
                    <Route path="/page1" element={<Page1 width={1920} height={1080}/>}/>
                    <Route path="/page2" element={<Page2 width={1920} height={1080}/>}/>
                    <Route path="/page3" element={<Page3 width={1920} height={1080}/>}/>
                    <Route path="/page4" element={<Page4 width={1920} height={1080}/>}/>
                    <Route path="/page5" element={<Page5 width={1920} height={1080}/>}/>
                    <Route path="/page6" element={<Page6 width={1920} height={1080}/>}/>
                    <Route path="/page7" element={<Page7 width={1920} height={1080}/>}/>
                    <Route path="/page8" element={<Page8 width={3655} height={1044}/>}/>
                    <Route path="/page9" element={<Page9 width={1920} height={1080}/>}/>
                    <Route path="/page10" element={<Page10 width={1920} height={1080}/>}/>
                    <Route path="/page_drill_1" element={<PageDrill1 width={1280} height={840}/>}/>
                    <Route path="/page_drill_2" element={<PageDrill2 width={1280} height={840}/>}/>
                    <Route path="/page_drill_3" element={<PageDrill3 width={1280} height={840}/>}/>
                    <Route path="/page_drill_4" element={<PageDrill4 width={1280} height={840}/>}/>                    
                    <Route path="/page_drill_5" element={<PageDrill5 width={1280} height={840}/>}/>                                        
                    <Route path="/page_drill_6" element={<PageDrill6 width={1280} height={840}/>}/>                                                            
                    <Route path="/page_test" element={<PageTest width={1920} height={1080}/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
