import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import './carousel.scss';
import { getEnterpriseLogoList } from "../../../api"
const CarouselPage: React.FC = () => {
    const [list, setList] = useState<any>([]);
    const twoDimensionalArrayFun = (oneDimensionalArray: any) => {
        const twoDimensionalArray = Array.from(
            { length: Math.ceil(oneDimensionalArray.length / 9) },
            (_, i) => oneDimensionalArray.slice(i * 9, i * 9 + 9)
        );
        return twoDimensionalArray
    }

    const getEnterpriseLogoListFun = async () => {
        const ctx: any = await getEnterpriseLogoList({ limit: 270 });
        const enterprise_logo = twoDimensionalArrayFun(ctx.data.enterprise_logo);
        setList(enterprise_logo)
    }

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: 'linear',
    };
    useEffect(() => {
        getEnterpriseLogoListFun()
    }, []);

    return (
        <Carousel {...settings} id={'carousel_page7'}>
            {list.map((it:any, index:any) => {
                const image = it.map((item:any, i:any) => {
                    return (
                        <div key={`${index}_${i}`}>
                            <div className={'img_box'}>
                                <img src={item} alt={item} />
                            </div>
                            {/*<div className={'title'}>{item.title}</div>*/}
                        </div>
                    );
                });

                return (
                    <div key={index} className={'carousel_box'}>
                        {image}
                    </div>
                );
            })}
        </Carousel>
    );
};

export default CarouselPage;
