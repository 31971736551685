import {getEducationStatisticsList} from "../../../api"
const chart2 = async() => {
    const ctx:any=await getEducationStatisticsList({});
    const xData=ctx.data.education_statistics.map((v:any)=>{
        return v.education_name
    })
    const yData= ctx.data.education_statistics.map((v:any)=>{
        return v.education_resume_num
    })
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: xData
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: yData,
            }
        ]
    };
};
export default chart2
