const chart2 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: { // 控制表格位置
            x: '80px',
            x2: '40px',
            y: '20px',
            y2: '40px',
        },
        xAxis: {
            type: 'value',
            splitLine: { // 分隔线
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: '18px',
            },
            width: 200,
            data: [
                "职员",
                "普工",
                "员工",
                "职工",
                "销售",
                "教师",
                "工作人员",
                "文员",
                "操作工",
                "业务员",
            ].reverse(),
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                data: [
                    17631,
                    7788,
                    7558,
                    6921,
                    3862,
                    3670,
                    2462,
                    1780,
                    1472,
                    1456,
                ].reverse(),
                itemStyle: {
                    borderRadius: [0, 10, 10, 0],
                    color: 'rgba(35, 183, 229, 0.8)',
                }
            },
        ]
    };
};
export default chart2
