const chart3 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                "置业顾问",
                "服务员",
                "小程序开发",
                "摄影摄像",
                "客服",
                "导演",
                "带货主播",
                "前端工程师",
                "JAVA程序员",
                "超市导购"
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: [
                    1542,
                    1425,
                    1125,
                    1107,
                    1077,
                    811,
                    218,
                    128,
                    21,
                    1
                ],
            }
        ]
    };
};
export default chart3
