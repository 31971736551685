import React, { useEffect, useState } from 'react';
import { Card, Space } from 'antd';
import style from './PageDrill5.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import { useLocation } from "react-router-dom";
import { getResumeData } from "../../api"
import { wrap } from 'module';

interface PageDrill5Props {
    width: number;
    height: number;
}

const PageDrill5: React.FC<PageDrill5Props> = (props) => {
    const { width, height } = props
    const [resume, setResume] = useState<any>({});
    const [resumeEducation, setResumeEducation] = useState<any>([]);
    const [resumeWork, setResumeWork] = useState<any>([]);
    const [resumeProject, setResumeProject] = useState<any>([]);
    const [resumeAbility, setResumeAbility] = useState<any>([]);
    const location = useLocation();
    const region_id = (new URLSearchParams(location.search)).get('region_id') || '1';
    const toStr = (str: string) => {
        let arr: any = [];
        if (str) {
            arr = str.split(",")
        }
        return arr
    }
    const toParse = (str: string) => {
        if (str) {
            return JSON.parse(str)
        } else {
            return []
        }
    }
    const toArr = (str: string) => {
        let arr: any = [];
        if (str) {
            arr = str.split(",")
        }
        return arr
    }
    const getResumeDataFun = async () => {
        const ctx = await getResumeData({ resume_id: region_id })
        const resume = ctx.data.resume;
        const resumeEducation = toParse(resume.resume_education)
        const resumeWork = toParse(resume.resume_work)
        const resumeProject = toParse(resume.resume_project)
        const resumeAbility = toArr(resume.resume_ability)
        console.log('resumeEducation', resumeEducation)
        setResume(resume)
        setResumeEducation(resumeEducation);
        setResumeWork(resumeWork);
        setResumeProject(resumeProject);
        setResumeAbility(resumeAbility);
    }

    useEffect(() => {
        getResumeDataFun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1} />
            </div>
            <div className={style.scrollBar}>
                <div style={{ fontSize: '28px', color: '#fff',}}>{resume.resume_name}</div>
                <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px', marginTop:'20px' }}>
                    {
                        resume.resume_workyear ? <><span>{resume.resume_workyear}年工作经验</span><span style={{ margin: '0 5px' }}>|</span></> : <></>
                    }
                    {
                        resume.age ? <><span>{resume.age}</span><span style={{ margin: '0 5px' }}>|</span></> : <></>
                    }
                    {
                        resume.education_name ? <><span>{resume.education_name}</span><span style={{ margin: '0 5px' }}>|</span></> : <></>
                    }
                    {
                        resume.major_name ? <><span>{resume.major_name}</span></> : <span>{resume.resume_major}</span>
                    }
                </div>

                <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px', marginTop:'30px'}}>{resume.enterprise_name}</div>

                <div style={{ marginTop:'30px' }}>
                    <div style={{}}>
                        <RedDotTitle title={`求职意向`} />
                    </div>
                    <div className={style.listStyle}>
                        <div>期望职位：{resume.resume_hope}</div>
                        <div>求职方向：{toStr(resume.category_name)}</div>
                        <div>期望行业：{toStr(resume.industry_name)}</div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <RedDotTitle title={`教育经历`} />
                    </div>
                    {resumeEducation.map((it: any, index: any) => (
                        <div style={{ color: '#fff', fontSize: '22px', marginTop: '20px' }} key={index}>{it.start_time}至{it.end_time}&nbsp;&nbsp;{it.school_name}&nbsp;&nbsp;{it.major}</div>
                    ))}
                    <div style={{ marginTop: '30px' }}>
                        <RedDotTitle title={`工作经历`} />
                    </div>
                    {resumeWork.map((it: any, index: any) => (
                        <div style={{ color: '#fff', fontSize: '22px', marginTop: '20px' }} key={index}>{it.campany_name}&nbsp;&nbsp;{it.job}&nbsp;&nbsp;{it.year}</div>
                    ))}
                    <div style={{ marginTop: '30px' }}>
                        <RedDotTitle title={`项目经历`} />
                    </div>
                    {resumeProject.map((it: any, index: any) => (
                        <div style={{ color: '#fff', fontSize: '22px', marginTop: '20px' }} key={index}>{it.start_time}至{it.end_time}&nbsp;&nbsp;{it.project_name}&nbsp;&nbsp;{it.role}&nbsp;&nbsp;{it.desc}</div>
                    ))}
                    <div style={{ marginTop: '30px' }}>
                        <RedDotTitle title={`个人能力`} />
                    </div>
                    {resumeAbility.map((it: any, index: any) => (
                        <div key={index} style={{ color: '#fff', fontSize: '22px', marginTop: '20px' }}>{it}</div>
                    ))}
                    <div className={style.listStyle}>{resume.resume_introducton ? resume.resume_introducton : ''}</div>
                </div>
            </div>

        </PageBox>
    );
}

export default PageDrill5;
