import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import {ScrollBoard} from '@jiaminghi/data-view-react'
import style from './Page3.module.css';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import chart4 from "./components/chart4";

interface Page3Props {
    width: number;
    height: number;
}

const Page3: React.FC<Page3Props> = (props) => {
    const {width, height} = props

    const scrollBoardConfig1 = {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['专场招聘会名称', '参与企业', '发布职位', '收到简历数', '访问量'],
        rowNum: 6,
        data: [
            ['唐山市“揽英才，创未来”万企入校园招聘活动 之西南交通大学、四川大学、华西医学院专场', '607', '1664', '14427', '1010048'],
            ['“揽英才 创未来”万企入校园招聘活动—河北科技大学唐山专场', '30', '137', '1985', '4110'],
            ['“揽英才 创未来”万企入校园招聘活动—北京大学唐山专场', '89', '357', '3543', '31773'],
            ['“揽英才 创未来”万企入校园招聘活动—天津大学唐山专场', '47', '159', '2897', '7473'],
            ['2023年2月份第三周“最新岗位“”推荐', '7', '27', '219', '189'],
            ['2023.3.23日-24日直播岗位报名', '25', '138', '2280', '3450'],
            ['“揽英才 创未来”万企入校园招聘活动—上海高校唐山专场', '89', '357', '3543', '31773'],
            ['2023.3.31日直播岗位报名', '32', '185', '3304', '5920'],
            ['“揽英才 创未来”万企入校园招聘活动—河北工业大学唐山专场', '48', '217', '2672', '10416'],
            ['2023.4.3日直播岗位报名', '35', '168', '2944', '5880'],
            ['“揽英才 创未来”万企入校园招聘活动—优质好岗专场', '174', '1002', '10341', '174348'],
            ['2023.4.4日直播岗位报名', '41', '220', '3927', '9020'],
            ['惠达之光 2023高校毕业生就业论坛—百所高校进唐山', '404', '1127', '13131', '455308'],
            ['2023.4.6日直播岗位报名', '9', '74', '1753', '666'],
            ['2023.4.7-10日直播岗位报名', '10', '60', '938', '600'],
            ['“揽英才 创未来”2023年唐山市万企入校园—西安电子科技大学唐山招聘专场', '20', '69', '1539', '1380'],
            ['“揽英才 创未来”2023年唐山市万企入校园—西北工业大学唐山招聘专场', '20', '69', '1539', '1380'],
            ['2023.4.11日直播岗位报名', '12', '47', '1130', '564'],
            ['2023.4.12日直播岗位报名', '13', '35', '679', '455'],
            ['“揽英才 创未来”千企入校园招聘活动 唐山企业赴石家庄铁道大学招聘专场', '19', '89', '707', '1691'],
            ['2023.4.13日直播岗位报名', '20', '99', '1093', '1980'],
            ['2023.4.14日直播岗位报名', '9', '44', '931', '396'],
            ['“揽英才 创未来”万企入校园招聘活动—吉林大学唐山专场', '178', '455', '5533', '80990'],
            ['“揽英才 创未来”万企入校园—北京化工大学就业双选会唐山专场', '227', '803', '9564', '182281'],
            ['揽英才 创未来”万企入校园—北京科技大学就业双选会唐山专场', '246', '792', '7798', '194832'],
            ['“揽英才·创未来”2023年唐山市万企入校园活动-河北科技大学唐山专场招聘会', '70', '336', '5274', '23520'],
            ['“揽英才·创未来”2023年唐山市万企入校园活动-西北大学唐山专场', '49', '257', '3930', '12593'],
            ['“揽英才·创未来”2023年唐山市万企入校园活动-河北工业大学唐山专场招聘会', '51', '264', '3711', '13464'],
            ['玉田高校毕业生暨青年人才招聘会专场', '70', '182', '524', '12740'],
            ['2023第十七届环渤海人才网络招聘大会（部分单位招聘信息持续更新中）', '104', '373', '5561', '38792'],
            ['唐山市第二届“凤还巢”人才发展推介大会', '217', '1029', '7568', '223293'],
        ]
    }

    const scrollBoardConfig2 = {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['专场招聘会名称', '参与企业', '发布职位', '人流量'],
        rowNum: 6,
        hoverPause: false, // 在 数据条数 和 rowNum 相等时，设置这个可以避免鼠标悬停报错
        // waitTime: 1000000000, // 不知道怎么暂停表格滚动，直接设置一个很大的等待时间，但是貌似数据正好一页的时候，就直接不滚动了
        data: [
            ['筑巢搭台聚贤能· 引育人才助发展第三届玉田印刷包装机械国际博览会—玉田高校毕业生暨青年人才招聘会', '70', '2337', '163590'],
            ['揽英才 创未来” 唐山市秋季万企入校园——北京理工大学校园线下招聘活动', '16', '263', '4208'],
            ['唐山市第三届高质量发展人才技术交流大会', '121', '357', '43197'],
            ['2023•第十七届环渤海人才网络招聘大会', '700', '14000', '47839'],
            ['第四届精英人才竞聘会', '10', '64', '218'],
            ['“2023年唐山市国有企业大型招聘会”', '118', '1095', '129210'],
        ]
    }

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    let _Chart3: EChartsType
    const domRefChart3: any = useRef();
    let _Chart4: EChartsType
    const domRefChart4: any = useRef();

    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current);
        _Chart2.setOption(chart2());

        if (_Chart3) _Chart3.dispose()
        _Chart3 = echarts.init(domRefChart3.current);
        _Chart3.setOption(chart3());

        if (_Chart4) _Chart4.dispose()
        _Chart4 = echarts.init(domRefChart4.current);
        _Chart4.setOption(chart4());
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox width={width} height={height}>
                <div className={style.background}></div>
                <Headline title={'人才招聘（招）'} fontSize="46" type={'text'}/>

                {/* 关键数据 */}
                <div style={{position: 'absolute', left: '570px', top: '180px',}}>
                    <RedDotTitle title={'关键数据'}/>
                </div>
                {/* 关键数据 */}
                <div style={{position: 'absolute', left: '570px', top: '240px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '累计参与人数', value: '1,500,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.8), rgba(23, 95, 190, 0.8))',
                            },
                            {
                                title: '达成就业意向', value: '64,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '平台合作企业', value: '5,300+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                </div>
                {/* 关键数据 */}
                <div style={{position: 'absolute', left: '570px', top: '370px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '平台求职者', value: '1,250,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.8), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '发布职位数', value: '26,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.3))',
                            },
                            {
                                title: '接收简历数', value: '530,001+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才网'} link={'https://www.tsrcw.com'} right={'0'}/>
                </div>

                {/* 用户画像-饼图 */}
                <div style={{position: 'absolute', left: '120px', top: '120px',}}>
                    <div style={{
                        width: '380px', height: '230px',
                    }}
                         ref={domRefChart1}
                    />
                </div>
                {/* 用户画像 */}
                <div style={{position: 'absolute', left: '50px', top: '70px',}}>
                    <RedDotTitle title={'用户画像'}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 学历分布-状状图 */}
                <div style={{position: 'absolute', left: '40px', top: '350px',}}>
                    <div style={{
                        width: '540px', height: '260px',
                    }}
                         ref={domRefChart2}
                    />
                </div>
                {/* 学历分布 */}
                <div style={{position: 'absolute', left: '50px', top: '320px',}}>
                    <RedDotTitle title={'学历分布'}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 热门职位TOP10-状状图 */}
                <div style={{position: 'absolute', left: '1420px', top: '100px',}}>
                    <div style={{
                        width: '500px', height: '260px',
                    }}
                         ref={domRefChart3}
                    />
                </div>
                {/* 热门职位TOP10 */}
                <div style={{position: 'absolute', left: '1460px', top: '70px',}}>
                    <RedDotTitle title={'热门职位TOP10'}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 职位薪资TOP10-状状图 */}
                <div style={{position: 'absolute', left: '1420px', top: '380px',}}>
                    <div style={{
                        width: '500px', height: '260px',
                    }}
                         ref={domRefChart4}
                    />
                </div>
                {/* 职位薪资TOP10 */}
                <div style={{position: 'absolute', left: '1460px', top: '360px',}}>
                    <RedDotTitle title={'职位薪资TOP10'}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 线上专场招聘 */}
                <div style={{position: 'absolute', left: '60px', top: '605px',}}>
                    <RedDotTitle title={'线上专场招聘'}/>
                </div>
                {/* 线上专场招聘-表格 */}
                <div style={{position: 'absolute', left: '60px', top: '650px',}}>
                    <ScrollBoard config={scrollBoardConfig1} style={{width: '865px', height: '370px'}}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'} right={'0'}
                              bottom={'-30px'}/>
                </div>
                {/* 线下专场招聘 */}
                <div style={{position: 'absolute', left: '995px', top: '605px',}}>
                    <RedDotTitle title={'线下专场招聘'}/>
                </div>
                {/* 线下专场招聘-表格 */}
                <div style={{position: 'absolute', left: '995px', top: '650px',}}>
                    <ScrollBoard config={scrollBoardConfig2} style={{width: '865px', height: '370px'}}/>
                    <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'} right={'0'}
                              bottom={'-30px'}/>
                </div>
            </PageBox>
        </>
    );
}

export default Page3;
