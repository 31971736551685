const chart2 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: { // 控制表格位置
            x: '140px',
            x2: '40px',
        },
        xAxis: {
            type: 'value',
            splitLine: { // 分隔线
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: '18px',
            },
            width: 200,
            data: [
                "小程序",
                "视频号",
                "抖音号",
                "快手号",
                "人才市场公众号",
                "唐山人才网",
                "集团公众号"
            ],
        },
        series: [
            {
                name: '今年',
                type: 'bar',
                barWidth: 18,
                data: [
                    157900,
                    10000,
                    20000,
                    190000,
                    176000,
                    120000,
                    11806
                ],
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
        ]
    };
};
export default chart2
