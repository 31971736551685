import DataStore from '../data/人才存量.json'

const chart1 = (region_name: string) => {
    const data = DataStore.filter((it) => {
        return it.region === region_name
    })[0]

    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            data: ['22第四季度', '23第一季度', '23第二季度', '23第三季度',]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
        },
        series: [
            {
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    color: 'inherit',
                    fontWeight: 'bold',
                },
                data: [
                    {
                        value: data['22_4'],
                        itemStyle: {
                            color: '#724e58'
                        }
                    },
                    {
                        value: data['23_1'],
                        itemStyle: {
                            color: '#6e7074'
                        }
                    },
                    {
                        value: data['23_2'],
                        itemStyle: {
                            color: '#61a0a8'
                        }
                    },
                    {
                        value: data['23_3'],
                        itemStyle: {
                            color: '#cc7e63'
                        }
                    },],
            }
        ]
    };
};
export default chart1
