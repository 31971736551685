import DataStore from '../data/学历占比.json'
const chart3 = (region_name:string) => {
    const data = DataStore.filter((it) => {
        return it.region === region_name
    })[0]
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['50%', '60%'],
                itemStyle: {
                    borderRadius: 20,
                },
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    margin: 10,
                    textStyle: {
                        color: 'inherit', // 继承颜色
                        fontSize: '18px',
                    },
                    formatter: (params: any) => {
                        return `${params.name || '-'}: ${params.value}`
                    }
                },
                data: [
                    {
                        "name": "本科",
                        "value": data['本科']
                    },
                    {
                        "name": "硕士研究生",
                        "value": data['硕士研究生']
                    },
                    {
                        "name": "博士研究生",
                        "value": data['博士研究生']
                    },
                    {
                        "name": "大专",
                        "value": data['专科']
                    },
                    {
                        "name": "其他",
                        "value": data['其他']
                    },
                    {
                        "name": "中专（高中）",
                        "value": data['中专（高中）']
                    },
                    {
                        "name": "未采集",
                        "value": data['未采集']
                    }
                ]
            }
        ]
    };
};
export default chart3
