import React, {CSSProperties} from 'react';
import {Property} from "csstype";

interface FootNoteProps {
    title: string,
    link: string,

    bottom?: string,
    left?: string,
    right?: string,
    width?: string,
    textAlign?: Property.TextAlign,
}

const FootNote: React.FC<FootNoteProps> = (props) => {
    const {
        title, link,
        bottom = '-20px', left, right
        , width = 'auto', textAlign = 'end',
    } = props
    const getStyles = () => {
        const styles: CSSProperties = {width: width, position: 'absolute', bottom: bottom, textAlign: textAlign,}
        if (left) {
            styles.left = left
        } else if (right) {
            styles.right = right
        }
        return styles
    }
    return (
        <div style={getStyles()}>
            {link.length === 0
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                ? <a style={{
                    color: 'rgba(255,255,255,0.2)',
                    textDecoration: 'none', width: '100px',
                    fontSize: '20px', lineHeight: '24px',
                }} rel="noreferrer"
                >{title}</a>
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                : <a href={link} target="_blank"
                     style={{
                         color: 'rgba(255,255,255,0.2)',
                         textDecoration: 'none', width: '100px',
                         fontSize: '20px', lineHeight: '24px',
                     }} rel="noreferrer"
                >{title}</a>
            }
        </div>
    );
}

export default FootNote;
