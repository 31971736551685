const chart1 = () => {
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['60%', '70%'],
                startAngle: 45,
                itemStyle: {
                    borderRadius: 20,
                },
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    margin: 10,
                    textStyle: {
                        color: 'rgba(35, 183, 229, 0.8)',
                        fontSize: '28px',
                    },
                },
                data: [
                    {
                        value: 87478, name: '男生', itemStyle: {
                            color: 'rgba(35, 183, 229, 0.3)',
                        }
                    },
                    {
                        value: 80322, name: '女生', itemStyle: {
                            color: 'rgba(35, 183, 229, 0.6)',
                        }
                    },
                ]
            }
        ]
    };
};
export default chart1
