import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import {ScrollBoard} from "@jiaminghi/data-view-react";
import FlipNumbers from "react-flip-numbers";
import style from './Page4.module.css';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chart1 from "./components/chart1";
import CarouselPage from "./components/carousel";
import Decoration from "../../components/Decoration";

interface Page4Props {
    width: number;
    height: number;
}

const Page4: React.FC<Page4Props> = (props) => {
    const {width, height} = props

    const scrollBoardConfig1 = {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['培训会名称'],
        rowNum: 4,
        data: [
            ['TTT内训师培训实战特训营'],
            ['唐山人才集团空中乘务和机场综合服务员选拔项目'],
            ['市级专业技术人员科技创新高级研修班'],
            ['“全面预算管理实务”专题培训班'],
            ['高级职业经理资质培训与评价工作'],
            ['高级职业经理人企业上市和资本运作专题培训班'],
            ['唐山市高级职业经理人培训——领导力提升专场活动'],
            ['精准招商引资策略与实战技巧专题培训班'],
            ['“职场礼仪提升”专题培训班'],
            ['唐山市高级职业经理人培训—财务管控与经营管理专场活动'],
            ['2023年唐山市高级职业经理人培训——战略规划与实施专场活动'],
            ['“唐山市机关事业单位干部素质提升”培训班'],
            ['2023年唐山市高级职业经理人首场培训—人力资源管理能力提升专场活动'],
            ['“企业经营活动劳动争议处理技巧”培训班'],
        ],
    }
    const scrollBoardConfig2 = {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['活动名称', '参与人员数', '答题汇总数',],
        hoverPause: false,
        rowNum: 4,
        data: [
            {
                "subsys": "工伤保险知识有奖竞答（2023年2月）",
                "module": "42266",
                "business": "295862"
            },
            {
                "subsys": "工伤保险知识有奖竞答（2023年09月）",
                "module": "24936",
                "business": "174552"
            },
            {
                "subsys": "危险化学品行业工伤预防知识竞答（2023年10月）",
                "module": "20496",
                "business": "143472"
            },
            {
                "subsys": "交通运输行业工伤预防知识竞答（2023年12月）",
                "module": "15443",
                "business": "108101"
            }
        ].map((it) => {
            return [it.subsys, it.module, it.business]
        }),
    }

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();

    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox width={width} height={height}>
                <div className={style.background}></div>
                <div style={{
                    position: 'absolute', left: '1650px', top: '455px',
                    width: '400px', height: '400px',
                }}>
                    <Decoration opacity={0.1}/>
                </div>
                <div style={{
                    position: 'absolute', left: '910px', top: '920px',
                    width: '200px', height: '200px',
                }}>
                    <Decoration opacity={0.1}/>
                </div>
                <Headline title={'人才培育（育）'} fontSize="46" type={'text'}/>

                {/* 汇总指标 */}
                <div style={{position: 'absolute', left: '50px', top: '120px',}}>
                    <RedDotTitle title={'汇总指标'}/>
                </div>
                {/* 汇总指标 */}
                <div style={{position: 'absolute', left: '50px', top: '160px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '培训场次', value: '129+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '培训人才', value: '16,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              right={'0'}/>
                </div>

                {/* 考试培训场次 */}
                <div style={{position: 'absolute', left: '50px', top: '280px',}}>
                    <RedDotTitle title={'考试培训场次'}/>
                </div>
                {/* 考试培训场次 */}
                <div style={{position: 'absolute', left: '50px', top: '320px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '考试培训场次数', value: '128+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '覆盖考生数', value: '47,000余人',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              right={'0'}/>
                </div>

                {/* 培训课程数据 */}
                <div style={{position: 'absolute', left: '50px', top: '440px',}}>
                    <RedDotTitle title={'培训课程数据'}/>
                </div>
                {/* 培训课程数据 */}
                <div style={{position: 'absolute', left: '50px', top: '480px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '课程总数', value: '282+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '课程数量', value: '27,100+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              right={'0'}/>
                </div>

                {/* 人力资源服务汇总 */}
                <div style={{position: 'absolute', left: '50px', top: '600px',}}>
                    <RedDotTitle title={'人力资源服务汇总'}/>
                </div>
                {/* 人力资源服务汇总 */}
                <div style={{position: 'absolute', left: '50px', top: '640px',}}>
                    <Space
                        style={{
                            width: '800px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '人事档案', value: '283,500+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '保险服务退休代办', value: '51,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                            {
                                title: '人才派遣单位', value: '500余家',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.4))',
                            },
                            {
                                title: '人才派遣', value: '15,000余人',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才集团劳务派遣系统'} link={'https://tslwpq.qinghuiche.com/admin'}
                              right={'0'}/>
                </div>

                {/* 培训会汇总 */}
                <div style={{position: 'absolute', left: '50px', top: '780px',}}>
                    <RedDotTitle title={'培训会汇总'}/>
                </div>
                {/* 培训会汇总-表格 */}
                <div style={{position: 'absolute', left: '50px', top: '825px',}}>
                    <ScrollBoard config={scrollBoardConfig1} style={{width: '395px', height: '200px'}}/>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              right={'0'}
                              bottom={'-30px'}/>
                </div>

                {/* 线上活动汇总 */}
                <div style={{position: 'absolute', left: '450px', top: '780px',}}>
                    <RedDotTitle title={'线上活动汇总'}/>
                </div>
                {/* 线上活动汇总-表格 */}
                <div style={{position: 'absolute', left: '450px', top: '825px',}}>
                    <ScrollBoard config={scrollBoardConfig2} style={{width: '400px', height: '200px'}}/>
                    <FootNote title={'数据来源：线上活动汇总'} link={''} right={'0'}
                              bottom={'-30px'}/>
                </div>

                {/* 翻盘器 */}
                <div
                    style={{position: 'absolute', left: '960px', top: '200px',}}>
                    <FlipNumbers
                        numbers="18,000"
                        height={80} width={54} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '38px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                {/* 翻盘器 */}
                <div
                    style={{position: 'absolute', left: '1440px', top: '200px',}}>
                    <FlipNumbers
                        numbers="1,201,082"
                        height={80} width={54} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '38px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                {/* 职业技能人员培训学员总数 */}
                <div style={{position: 'absolute', left: '940px', top: '120px',}}>
                    <RedDotTitle title={'职业技能人员培训学员总数'}/>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
                {/* 职业技能人员学习总时长 */}
                <div style={{position: 'absolute', left: '1420px', top: '120px',}}>
                    <RedDotTitle title={'职业技能人员学习总时长'}/>
                    <FootNote title={'数据来源：唐山人才集团培训平台'} link={'http://tspx.qinghuiche.com/admin'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                <div style={{position: 'absolute', left: '900px', top: '260px',}}>
                    <div style={{
                        width: '1050px', height: '360px',
                    }}
                         ref={domRefChart1}
                    />
                </div>

                <div style={{position: 'absolute', left: '960px', top: '600px',}}>
                    <div style={{
                        width: '900px', height: '460px',
                    }}>
                        <CarouselPage/>
                    </div>
                </div>

            </PageBox>
        </>
    );
}

export default Page4;
