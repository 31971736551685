import {Carousel} from 'antd';
import React from 'react';
import './carousel.scss';

const CarouselPage: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: 'linear',
    };
    const list = [
        {
            title: '智慧创造价值· 创新点亮未来—唐山市开平区重点产业高端人才技术项目对接会',
            img: require('../image/confab/640.jpeg'),
        },
        {
            title: '2023 年中关村科技人才‘一对一’ 服务唐山行',
            img: require('../image/confab/641.png'),
        },
        {
            title: '2023 年中关村科技人才‘一对一’ 服务唐山行',
            img: require('../image/confab/642.png'),
        },
        {
            title: '中国农业科学院高端人才唐山行暨乡村产业发展战略学研究会揭牌、 签约仪式',
            img: require('../image/confab/643.jpeg'),
        },
        {
            title: '高级职业经理资质培训与评价专题培训班',
            img: require('../image/confab/644.jpeg'),
        },
        {
            title: '唐山人才大道项目在第二届全国人力资源服务业发展大会上正式签约',
            img: require('../image/confab/645.jpeg'),
        },
        {
            title: '唐山市2023年北京大学、吉林大学“名校进唐山”莘莘学子暑期社会实践活动',
            img: require('../image/confab/646.jpeg'),
        },
        {
            title: '2023年中关村科技人才“一对一”服务唐山行——瑞丰钢铁专场活动',
            img: require('../image/confab/647.jpeg'),
        },
        {
            title: '创新引领·人才赋能—2023唐山市重点产业高端人才技术项目对接会',
            img: require('../image/confab/648.jpeg'),
        },
        {
            title: '2023年中关村科技人才“一对一”服务唐山行活动”首钢京唐专场',
            img: require('../image/confab/649.jpeg'),
        },
        {
            title: '河北省第一届职业技能大赛',
            img: require('../image/confab/650.jpeg'),
        },
        {
            title: '惠达之光2023高校毕业生就业论坛——百所高校进唐山活动',
            img: require('../image/confab/651.jpeg'),
        },
    ];
    return (
        <Carousel {...settings} id={'carousel_page5'}>
            {list.map((it, index) => {
                return (
                    <div key={index} className={'carousel_box'}>
                        <div className={'img_box'}>
                            <img src={it.img} alt={it.title}/>
                        </div>
                        <div className={'title'}>{it.title}</div>
                    </div>
                );
            })}
        </Carousel>
    );
};

export default CarouselPage;
