const chart4 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                '遵化市',
                '迁安市',
                '滦州县',
                '玉田县',
                '迁西县',
                '滦南县',
                '乐亭县',
                '曹妃甸区',
                '丰南区',
                '丰润区',
                '路北区',
                '路南区',
                '开平区',
                '古冶区',
                '高新区',
                '海港',
                '芦台',
                '汉沽',
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
        },
        series: [
            {
                name: '省内市外',
                type: 'line',
                yAxisIndex: 0,
                label: {
                    show: true,
                    position: 'top',
                    color: 'inherit',
                    fontWeight: 'bold',
                },
                data: [
                    84.33,
                    53.48,
                    38.99,
                    37.37,
                    33.66,
                    33.61,
                    54.63,
                    22.76,
                    21.3,
                    26.58,
                    41.91,
                    25.6,
                    27.68,
                    29.54,
                    25,
                    14.56,
                    89,
                    71.74,
                ],
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#787464',
                },
                itemStyle: {
                    borderColor: '#787464',
                    color: '#787464'
                }
            },
            {
                name: '国内省外',
                type: 'line',
                yAxisIndex: 0,
                label: {
                    show: true,
                    position: 'top',
                    color: 'inherit',
                    fontWeight: 'bold',
                },
                data: [
                    84.66,
                    56.17,
                    26.55,
                    26.45,
                    33.95,
                    27.16,
                    49.7,
                    20.45,
                    16.84,
                    29.12,
                    38,
                    27.69,
                    20,
                    15.79,
                    21.01,
                    32.35,
                    67.44,
                    66.67,
                ],
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#cc7e63',
                },
                itemStyle: {
                    borderColor: '#cc7e63',
                    color: '#cc7e63'
                }
            },
            {
                name: '国外',
                type: 'line',
                yAxisIndex: 0,
                label: {
                    show: true,
                    position: 'top',
                    color: 'inherit',
                    fontWeight: 'bold',
                },
                data: [
                    0,
                    28.57,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    25,
                    0,
                    37.5,
                    0,
                    0,
                    0,
                    14.29,
                    0,
                    0,
                    0,
                ],
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    color: '#61a0a8',
                },
                itemStyle: {
                    borderColor: '#61a0a8',
                    color: '#61a0a8'
                }
            },
        ]
    };
};
export default chart4
