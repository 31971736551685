import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import style from './Page_test.module.css';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import chinaMap from "./components/china_map";

interface Page6Props {
    width: number;
    height: number;
}

const PageTest: React.FC<Page6Props> = (props) => {
    const {width, height} = props

    const domRefChart1: any = useRef();

    const chartsInit = () => {
        const _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chinaMap());
    };
    useEffect(() => {
        chartsInit();
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>
            <Headline title={'测试'} fontSize="46" type={'text'}/>

            <div style={{position: 'absolute', left: '1050px', top: '225px',}}>
                <div
                    ref={domRefChart1}
                    style={{
                        width: '800px', height: '800px',
                        position: 'absolute', left: '75px', top: '-200px',
                        backgroundColor: 'rgba(255,0,0,0.2)'
                    }}/>
            </div>

            {/* 汇总指标 */}
            <div style={{position: 'absolute', left: '1195px', top: '120px',}}>
                <RedDotTitle title={'指标数据'}/>
            </div>
            {/* 汇总指标 */}
            <div style={{position: 'absolute', left: '1195px', top: '160px',}}>
                <Space
                    style={{
                        width: '740px', height: '110px',
                        display: 'flex', flexFlow: 'row',
                        overflow: 'hidden',
                    }}
                    styles={{item: {flex: 1,}}}
                >
                    {[
                        {
                            title: '累计引进人才量', value: '195,429',
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.8))',
                        },
                        {
                            title: '政府主导岗位招录数量', value: '10,020',
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.6))',
                        },
                        {
                            title: '非政府主导岗位招聘', value: '185,409',
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                        },
                    ].map((it, index) => (
                        <Card
                            key={index}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                background: it.gradient,
                                color: 'white',
                            }}
                            bodyStyle={{padding: '8px'}}
                            bordered={false}
                        >
                            <div style={{fontSize: '32px'}}>{it.value}</div>
                            <div style={{fontSize: '16px'}}>{it.title}</div>
                        </Card>
                    ))}
                </Space>
                <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
            </div>
        </PageBox>
    );
}

export default PageTest;
