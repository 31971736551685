import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import style from './Page2.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import RedDotTitleBold from "../../components/RedDotTitleBold";
import TagCanvas from "../../components/tagcanvas"
import Headline from "../../components/Headline";
import FlipNumbers from 'react-flip-numbers';
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";

// import chart3 from "./components/chart3";

interface Page2Props {
    width: number;
    height: number;
}

const Page2: React.FC<Page2Props> = (props) => {
    const {width, height} = props

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current);
        _Chart2.setOption(chart2());

        TagCanvas.Start('tagcanvas', 'taglist', {
            textColour: 'rgba(35, 183, 229, 0.8)',
            outlineColour: 'rgba(35, 183, 229, 0.3)',
            initial: [0.02, -0.02],
            dragControl: true,
            reverse: true,
            weight: true, // weight weightSize weightFrom 这哥三最好一起用，要不然字很小
            weightSize: 24,
            weightFrom: 'data-weight',
            depth: 0.8,
            maxSpeed: 0.05
        });
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox width={width} height={height}>
                {/*<div className={style.background}></div>*/}
                <Headline title={'多维推广（线上线下）'} fontSize="46" type={'text'}/>

                {/* 服务企业招聘 */}
                <div style={{position: 'absolute', left: '50px', top: '125px',}}>
                    <RedDotTitle title={'服务企业招聘'}/>
                </div>
                <div style={{position: 'absolute', left: '50px', top: '165px',}}>
                    <Space
                        style={{
                            width: '290px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '服务企业招聘', value: '5,300+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.8), rgba(23, 95, 190, 0.8))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才网'} link={'https://www.tsrcw.com'} right={'0'}/>
                </div>

                {/* 微信就业群 */}
                <div style={{position: 'absolute', left: '355px', top: '125px',}}>
                    <RedDotTitle title={'微信就业群'}/>
                </div>
                <div style={{position: 'absolute', left: '355px', top: '165px',}}>
                    <Space
                        style={{
                            width: '540px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '微信就业群数量', value: '95个+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '就业群人数', value: '47,000人+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：微信就业群'} link={''} right={'0'}/>
                </div>

                {/* 新闻传播 */}
                <div style={{position: 'absolute', left: '50px', top: '330px',}}>
                    <RedDotTitle title={'新闻传播'}/>
                </div>
                <div style={{position: 'absolute', left: '50px', top: '370px',}}>
                    <Space
                        style={{
                            width: '845px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{
                            item: {
                                flex: 1,
                            }
                        }}
                    >
                        {[
                            {
                                title: '公众号/人才网媒体新闻发布', value: '10,000余条',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.8), rgba(23, 95, 190, 0.8))',
                            },
                            {
                                title: '全网信息访问', value: '12,000,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：唐山人才集团公众号'} link={''} right={'0'}/>
                </div>

                {/* 直播播放时长 */}
                <div style={{position: 'absolute', left: '50px', top: '585px',}}>
                    <RedDotTitle title={'直播播放时长'}/>
                    <FootNote title={'数据来源：抖音/快手/视频号'} link={''}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
                <div style={{position: 'absolute', left: '0px', top: '645px',}}>
                    <div ref={domRefChart1} style={{width: '980px', height: '460px',}}/>
                </div>

                {/* 直播累计观看-翻盘器 */}
                <div style={{position: 'absolute', left: '1300px', top: '125px',}}>
                    <FlipNumbers
                        numbers="5,200,000"
                        height={90} width={60} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '38px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                <div style={{
                    position: 'absolute', left: '1830px', top: '180px',
                    color: 'white', fontSize: '32px', fontWeight: 'bold',
                }}>+
                </div>
                {/* 直播累计观看 */}
                <div style={{position: 'absolute', left: '1000px', top: '150px',}}>
                    <RedDotTitleBold title={'直播累计观看'}/>
                    <FootNote title={'数据来源：抖音/快手/视频号'} link={''}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 聚合媒体受众-翻盘器 */}
                <div style={{position: 'absolute', left: '1300px', top: '245px',}}>
                    <FlipNumbers
                        numbers="544,900"
                        height={90} width={60} color="#FFF" play
                        numberClassName={style.flip_numbers_background}
                        numberStyle={{fontSize: '28px', fontWeight: 'bold',}}
                        nonNumberClassName={style.flip_non_numbers_background}
                        nonNumberStyle={{fontSize: '38px', fontWeight: 'bold', color: 'white',}}
                    />
                </div>
                <div style={{
                    position: 'absolute', left: '1740px', top: '300px',
                    color: 'white', fontSize: '32px', fontWeight: 'bold',
                }}>+
                </div>
                {/* 聚合媒体受众 */}
                <div style={{position: 'absolute', left: '1000px', top: '265px',}}>
                    <RedDotTitleBold title={'聚合媒体受众'}/>
                    <FootNote title={'数据来源：全网媒体账号'} link={''}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
                {/* 横向柱状图 */}
                <div style={{position: 'absolute', left: '1020px', top: '300px',}}>
                    <div ref={domRefChart2} style={{width: '845px', height: '380px',}}/>
                </div>

                {/* 热词搜索 */}
                <div style={{
                    position: 'absolute', left: '1125px', top: '585px', width: '600px',
                    height: '600px',
                }}>
                    <canvas id="tagcanvas" width="600" height="600">
                        <div style={{display: 'none'}}>
                            <div id="taglist">
                                <ul>
                                    {[
                                        {title: "凤凰英才计划4.0", weight: 2,},
                                        {title: "唐山人才网",},
                                        {title: "事业招考",},
                                        {title: "劳务派遣",},
                                        {title: "国企招聘",},
                                        {title: "应届毕业生",},
                                        {title: "唐山学院",},
                                        {title: "华北理工大学",},
                                        {title: "唐山文旅集团",},
                                        {title: "唐山人才市场",},
                                        {title: "微信小程序",},
                                        {title: "幸福唐山",},
                                        {title: "回唐计划",},
                                        {title: "博士生",},
                                        {title: "唐山人才数字平台", weight: 2,},
                                    ].map((it, index) => {
                                        return <li key={index}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a data-weight={it.weight || 1}
                                               onClick={(e) => e.preventDefault()}>
                                                {it.title}
                                            </a>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </canvas>
                </div>
                {/* 热词搜索 */}
                <div style={{position: 'absolute', left: '1000px', top: '680px',}}>
                    <RedDotTitle title={'热词搜索'}/>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
            </PageBox>
        </>
    );
}

export default Page2;
