import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ScrollBoard } from '@jiaminghi/data-view-react'
import style from './Page9.module.scss';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import TileBackground from "../../components/TileBackground";
import CarouselPage from "./components/carousel";
import { getJobStatisticsList, getEnterpriseList, getJobList, getEnterpriseData, getJobData, getResumeList, getResumeData } from "../../api"
interface Page9Props {
    width: number;
    height: number;
}

const Page9: React.FC<Page9Props> = (props) => {
    const { width, height } = props;

    const [scrollBoardConfig2, setScrollBoardConfig2] = useState<any>([]);
    const [scrollBoardConfig, setScrollBoardConfig] = useState<any>([]);
    const [scrollBoardConfig3, setScrollBoardConfig3] = useState<any>([]);
    const [scrollBoardConfig4, setScrollBoardConfig4] = useState<any>([]);
    const [enterpriseId, setEnterpriseId] = useState<any>([]);
    const [jobId, setJobId] = useState<any>([]);
    const [resumeId, setResumeId] = useState<any>([]);
    const [appBaseURL, setAppBaseURL] = useState('');

    // 获取职位投递数据
    const getJobStatisticsListFun = async () => {
        const ctx: any = await getJobStatisticsList({});
        let jobData: any = [];
        const job_statistics = ctx.data.job_statistics;
        for (let i = 0; i < job_statistics.length; i++) {
            jobData.push([job_statistics[i].job_name,job_statistics[i].job_resume_num]);
        }
        const scrollBoardConfig = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['职位名称','投递简历数'],
            rowNum: 12,
            columnWidth: [265, 140],
            hoverPause: false, // 在 数据条数 和 rowNum 相等时，设置这个可以避免鼠标悬停报错
            // waitTime: 1000000000, // 不知道怎么暂停表格滚动，直接设置一个很大的等待时间，但是貌似数据正好一页的时候，就直接不滚动了
            data: jobData
        }
        setScrollBoardConfig2(scrollBoardConfig)
    };
    // 获取企业列表
    const getEnterpriseListFun = async () => {
        let enterprise_id_arr = [];
        const ctx: any = await getEnterpriseList({ limit: 200 });
        let enterpriseData: any = [];
        const enterprise = ctx.data.enterprise;
        for (let i = 0; i < enterprise.length; i++) {
            enterpriseData.push([enterprise[i].enterprise_name,enterprise[i].job_num]);
            enterprise_id_arr.push(enterprise[i].enterprise_id)
        }
        const scrollBoardConfig1 = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['企业名称', '职位数量'],
            rowNum: 12,
            columnWidth: [295,110],
            // waitTime:10000000,
            data: enterpriseData,
        }
        setScrollBoardConfig(scrollBoardConfig1)
        setEnterpriseId(enterprise_id_arr)
    };
    //获取职位列表
    const getJobListFun = async () => {
        let job_id_arr = [];
        const ctx: any = await getJobList({ limit: 200 });
        let jobData: any = [];
        const job = ctx.data.job;
        for (let i = 0; i < job.length; i++) {
            jobData.push([job[i].enterprise_name, job[i].job_name]);
            job_id_arr.push(job[i].job_id)
        }
        const scrollBoardConfig = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['企业名称', '职位名称'],
            rowNum: 12,
            data: jobData
        }
        setScrollBoardConfig3(scrollBoardConfig)
        setJobId(job_id_arr)
    };
    //获取简历列表
    const getResumeListFun = async () => {
        let resume_id_arr = [];
        const ctx: any = await getResumeList({ limit: 20 });
        let resumeData: any = [];
        const resume = ctx.data.resume;
        for (let i = 0; i < resume.length; i++) {
            let resume_education = JSON.parse(resume[i].resume_education);
            resumeData.push([resume[i].resume_name, resume[i].education_name, resume_education[0] ?.school_name]);
            resume_id_arr.push(resume[i].resume_id)
        }
        const scrollBoardConfig4 = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['姓名', '学历', '毕业院校'],
            rowNum: 12,
            columnWidth: [110, 120,175],
            data: resumeData
        }
        setScrollBoardConfig4(scrollBoardConfig4)
        setResumeId(resume_id_arr)
    };

    const [isModalOpen4, setIsModalOpen4] = useState([false, '', '']);
    const hideModal4 = () => {
        setIsModalOpen4([false, '', '']);
    };

    const [isModalOpen, setIsModalOpen] = useState([false, '', '']);
    const hideModal = () => {
        setIsModalOpen([false, '', '']);
    };
    const onEnterpriseRowClick = async (record: any) => {
        const enterprise_id = enterpriseId[record.rowIndex];
        let ctx = await getEnterpriseData({ enterprise_id: enterprise_id });
        setIsModalOpen([true, ctx.data ?.enterprise ?.enterprise_name, enterprise_id])
    };
    const [isModalOpen2, setIsModalOpen2] = useState([false, '', '']);
    const hideModal2 = () => {
        setIsModalOpen2([false, '', '']);
    };
    const onJobRowClick = async (record: any) => {
        const job_id = jobId[record.rowIndex];
        let ctx = await getJobData({ job_id: job_id });
        setIsModalOpen2([true, ctx.data ?.job ?.job_name, job_id])
    };
    const [isModalOpen3, setIsModalOpen3] = useState([false, '', '']);
    const hideModal3 = () => {
        setIsModalOpen3([false, '', '']);
    };
    const onResumeRowClick = async (record: any) => {
        const resume_id = resumeId[record.rowIndex];
        let ctx = await getResumeData({ resume_id: resume_id });
        setIsModalOpen3([true, ctx.data ?.resume ?.resume_name, resume_id])
    };
    useEffect(() => {
        getJobStatisticsListFun();
        getEnterpriseListFun();
        getJobListFun();
        getResumeListFun();
        setAppBaseURL((process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PRO) || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground />
            <PageBox width={width} height={height} id="page9">
                <div className={style.background}></div>
                <Headline title={'唐山路北区人才微信移动就业平台'} fontSize="36" type={'img'}/>
                <img src="./image/title.jpg" alt=""/>
                {/* 企业列表 */}
                <div style={{ position: 'absolute', left: '60px', top: '115px', }}>
                    <RedDotTitle title={'最新企业'} />
                </div>
                {/* 企业列表-表格 */}
                <div style={{ position: 'absolute', left: '60px', top: '160px', }}>
                    <ScrollBoard config={scrollBoardConfig} style={{ width: '405px', height: '750px' }} onClick={onEnterpriseRowClick} />
                </div>
                {/* 职位列表 */}
                <div style={{ position: 'absolute', left: '525px', top: '115px', }}>
                    <RedDotTitle title={'最新职位'} />
                </div>
                {/* 职位列表-表格 */}
                <div style={{ position: 'absolute', left: '525px', top: '160px', }}>
                    <ScrollBoard config={scrollBoardConfig3} style={{ width: '405px', height: '750px' }} onClick={onJobRowClick} />

                </div>
                {/* 热门职位 */}
                <div style={{ position: 'absolute', left: '990px', top: '115px', }}>
                    <RedDotTitle title={'热门职位'} />
                </div>
                {/* 热门职位-表格 */}
                <div style={{ position: 'absolute', left: '990px', top: '160px', }}>
                    <ScrollBoard config={scrollBoardConfig2} style={{ width: '405px', height: '750px' }} />

                </div>

                <div style={{ position: 'absolute', left: '60px', top: '960px', }}>
                    <div style={{
                        width: '1800px', height: '300px',
                    }}>
                        <CarouselPage />
                    </div>
                </div> 

                {/* 求职者 */}
                <div style={{ position: 'absolute', left: '1455px', top: '115px', }}>
                    <RedDotTitle title={'最新求职者'} />
                </div>
                {/* 求职者-表格 */}
                <div style={{ position: 'absolute', left: '1455px', top: '160px', }}>
                    <ScrollBoard config={scrollBoardConfig4} style={{ width: '405px', height: '750px' }} onClick={onResumeRowClick} />
                </div>
            </PageBox>
            <Modal
                title={isModalOpen[1]}
                open={isModalOpen[0] as boolean}
                onOk={hideModal}
                onCancel={hideModal}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)',
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen[1]}`}
                    width="100%"
                    height="100%"
                    style={{ overflow: 'hidden', border: 0 }}
                    src={`${appBaseURL}/page_drill_3?region_name=${isModalOpen[1]}&region_id=${isModalOpen[2]}`}
                />
            </Modal>
            <Modal
                title={isModalOpen2[1]}
                open={isModalOpen2[0] as boolean}
                onOk={hideModal2}
                onCancel={hideModal2}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)'
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen2[1]}`}
                    width="100%"
                    height="100%"
                    style={{ overflow: 'hidden', border: 0 }}
                    src={`${appBaseURL}/page_drill_4?region_name=${isModalOpen2[1]}&region_id=${isModalOpen2[2]}`}
                />
            </Modal>
            <Modal
                title={isModalOpen3[1]}
                open={isModalOpen3[0] as boolean}
                onOk={hideModal3}
                onCancel={hideModal3}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)',
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen3[1]}`}
                    width="100%"
                    height="100%"
                    style={{ overflow: 'hidden', border: 0 }}
                    src={`${appBaseURL}/page_drill_5?region_name=${isModalOpen3[1]}&region_id=${isModalOpen3[2]}`}
                />
            </Modal>
            <Modal
                title={isModalOpen4[1]}
                open={isModalOpen4[0] as boolean}
                onOk={hideModal4}
                onCancel={hideModal4}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)'
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen4[1]}`}
                    width="100%"
                    height="100%"
                    style={{ overflow: 'hidden', border: 0 }}
                    src={`${appBaseURL}/page_drill_6?region_name=${isModalOpen4[1]}&region_id=${isModalOpen4[2]}`}
                />
            </Modal>
        </>
    );
}

export default Page9;
