import React, {useEffect, useRef, useState} from 'react';
import {Card, Modal, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import style from './Page6.module.css';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chinaMap from "./components/china_map";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import chart4 from "./components/chart4";

interface Page6Props {
    width: number;
    height: number;
}

const Page6: React.FC<Page6Props> = (props) => {
    const {width, height} = props

    const [appBaseURL, setAppBaseURL] = useState('');
    const [isModalOpen, setIsModalOpen] = useState([false, '']);
    const hideModal = () => {
        setIsModalOpen([false, '']);
    };

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    let _Chart3: EChartsType
    const domRefChart3: any = useRef();
    let _Chart4: EChartsType
    const domRefChart4: any = useRef();

    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chinaMap());
        _Chart1.off('click')
        _Chart1.on('click', (params: any) => {
            setIsModalOpen([true, params.data.name])
        })

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current);
        _Chart2.setOption(chart2());

        if (_Chart3) _Chart3.dispose()
        _Chart3 = echarts.init(domRefChart3.current);
        _Chart3.setOption(chart3());

        if (_Chart4) _Chart4.dispose()
        _Chart4 = echarts.init(domRefChart4.current);
        _Chart4.setOption(chart4());
    };
    useEffect(() => {
        chartsInit();
        setAppBaseURL((process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PRO) || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox width={width} height={height}>
                <div className={style.background}></div>
                <Headline title={'人才引进（引）'} fontSize="46" type={'text'}/>
                <div title={'唐山人才地图'} style={{position: 'absolute', left: '50px', top: '125px',}}>
                    <RedDotTitle title={'人才留唐地图'}/>
                </div>
                <div style={{position: 'absolute', left: '-30px', top: '150px',}}>
                    <div
                        ref={domRefChart1}
                        style={{
                            width: '1000px', height: '1000px',
                            position: 'absolute', left: '75px', top: '-200px',
                            mask: 'linear-gradient(0deg, transparent, #fff 30%)',
                        }}/>
                </div>

                {/* 高校毕业生引进情况 */}
                <div style={{position: 'absolute', left: '1010px', top: '120px',}}>
                    <RedDotTitle title={'高校毕业生引进情况'}/>
                </div>
                {/* 高校毕业生引进情况 */}
                <div style={{position: 'absolute', left: '1010px', top: '160px',}}>
                    <Space
                        style={{
                            width: '810px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '累计引进人才量', value: '195,429',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.8))',
                            },
                            {
                                title: '政府主导岗位招录数量', value: '10,020',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '非政府主导岗位招聘', value: '185,409',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                              right={'0'}/>
                </div>

                {/* 驻唐高校应届毕业生就业率-柱状图 */}
                <div style={{position: 'absolute', left: '950px', top: '340px',}}>
                    <div style={{
                        width: '950px', height: '340px',
                    }}
                         ref={domRefChart2}
                    />
                </div>
                {/* 驻唐高校应届毕业生就业率 */}
                <div style={{position: 'absolute', left: '1010px', top: '320px',}}>
                    <RedDotTitle title={'驻唐高校应届毕业生就业率'}/>
                    <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 各县区累计引进数-柱状图 */}
                <div style={{position: 'absolute', left: '950px', top: '680px',}}>
                    <div style={{
                        width: '950px', height: '380px',
                    }}
                         ref={domRefChart3}
                    />
                </div>
                {/* 各县区累计引进数 */}
                <div style={{position: 'absolute', left: '1010px', top: '660px',}}>
                    <RedDotTitle title={'各县区累计引进数'}/>
                    <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>

                {/* 唐山籍高校毕业生情况-状状图 */}
                <div style={{position: 'absolute', left: '0px', top: '620px',}}>
                    <div style={{
                        width: '1050px', height: '440px',
                    }}
                         ref={domRefChart4}
                    />
                </div>
                {/* 唐山籍高校毕业生情况 */}
                <div style={{position: 'absolute', left: '50px', top: '585px',}}>
                    <RedDotTitle title={'唐山籍高校毕业生情况'}/>
                    <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
            </PageBox>
            <Modal
                title={"人才留唐"}
                open={isModalOpen[0] as boolean}
                onOk={hideModal}
                onCancel={hideModal}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)'
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen[1]}`}
                    width="100%"
                    height="100%"
                    style={{overflow: 'hidden', border: 0}}
                    src={`${appBaseURL}/page_drill_2?region_name=${isModalOpen[1]}`}
                />
            </Modal>
        </>
    );
}

export default Page6;
