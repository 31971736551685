import React, { useEffect, useState } from 'react';
import { Card, Space } from 'antd';
import style from './PageDrill4.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import { useLocation } from "react-router-dom";
import { getJobData } from "../../api"
import { wrap } from 'module';

interface PageDrill4Props {
    width: number;
    height: number;
}

const PageDrill4: React.FC<PageDrill4Props> = (props) => {
    const { width, height } = props
    const [jobData, setJobData] = useState<any>({});
    const [jobLabel, setJobLabel] = useState<any>([]);
    const location = useLocation();
    const region_id = (new URLSearchParams(location.search)).get('region_id') || '1';
    const getJobDataFun = async () => {
        const ctx = await getJobData({ job_id: region_id })
        let labelArr = [];
        labelArr.push(ctx.data.job.county_name);
        labelArr.push(ctx.data.job.education_name);
        if (ctx.data.job.job_label) {
            labelArr = [...labelArr, ...(ctx.data.job.job_label.split(","))]
        }
        console.log('labelArr', labelArr)
        setJobData(ctx.data.job)
        setJobLabel(labelArr)
    }
    useEffect(() => {
        getJobDataFun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1} />
            </div>
            <div className={style.scrollBar}>
                <div style={{ fontSize: '28px', color: '#fff' }}>{jobData.job_name}</div>
                <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px', marginTop: '20px' }}>{jobData.county_name}&nbsp;&nbsp;|&nbsp;&nbsp;经验{jobData.experience_name}&nbsp;&nbsp;|&nbsp;&nbsp;学历{jobData.education_name}</div>
                <div style={{ color: '#fff', fontSize: '28px', lineHeight: '34px', position:'absolute',right:'55px',top:0 }}>
                    {jobData.job_salary_min == -2 || jobData.job_salary_max == -2 ? '薪资面议' : ''}
                    {jobData.job_salary_min == null && jobData.job_salary_max == null ? '薪资面议' : ''}
                    {jobData.job_salary_min == null ? `${jobData.job_salary_max}${jobData.job_salary_unit}` : ''}
                    {jobData.job_salary_max == null ? `${jobData.job_salary_min}${jobData.job_salary_unit}` : ''}
                    {/* {jobData.job_salary_max == -2 ? `${jobData.job_salary_min}${jobData.job_salary_unit}-'面议'` : ''} */}
                    {jobData.job_salary_min && jobData.job_salary_min != -2 && jobData.job_salary_max && jobData.job_salary_max != -2 ? `${jobData.job_salary_min}-${jobData.job_salary_max}${jobData.job_salary_unit}` : ''}

                </div>
                <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px',  marginTop:'30px'  }}>{jobData.enterprise_name}</div>
                <div style={{  marginTop:'30px'  }}>
                    <RedDotTitle title={`职位详情`} />
                </div>
                <div style={{  marginTop:'30px' }}>
                    <Space
                        style={{
                            width: '1200px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                            flexWrap: 'wrap'
                        }}
                        styles={{ item: {} }}
                    >
                        {jobLabel.map((it: any, index: any) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.6))',
                                    color: 'white',
                                }}
                                bodyStyle={{ padding: '8px 10px' }}
                                bordered={false}
                            >
                                <div style={{ fontSize: '24px' }}>{it}</div>
                            </Card>
                        ))}
                    </Space>
                    {/*<FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>*/}
                    <div className={style.listStyle}>
                        <div>工作类型：{jobData.job_type_id == 1 ? '全职招聘' : jobData.job_type_id == 2 ? '兼职招聘' : '快速招聘'}</div>
                        <div>性别要求：{jobData.job_sex}</div>
                        <div>年龄要求：{jobData.job_age_min}-{jobData.job_age_max}岁</div>
                        <div>招聘人数：{jobData.job_recruit_num}人</div>
                        <div>{jobData.job_introduction}</div>

                    </div>
                </div>
            </div>
        </PageBox>
    );
}

export default PageDrill4;
