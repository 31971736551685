const chart1 = () =>  {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: [
            {
                type: 'category',
                data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月"],
                axisLine: {
                    show: true,
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '今年/往年',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            },
            {
                type: 'value',
                name: '',
                splitLine: { // 分隔线
                    show: false,
                },
            },
            {
                type: 'value',
                name: '增幅',
                axisLine: {
                    show: true,
                },
            }
        ],
        series: [
            {
                name: '今年',
                type: 'bar',
                data: [
                    59038,
                    69391,
                    64391,
                    24391,
                    84391,
                    86382,
                    21245,
                    32344,
                    32566,
                    32454,
                    23342
                ],
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
            {
                name: '往年',
                type: 'bar',
                data: [
                    56038,
                    64391,
                    60391,
                    22391,
                    83391,
                    80382,
                    20245,
                    30344,
                    31566,
                    30454,
                    21342
                ],
                barWidth: 18, // 柱状图宽度
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.3)',
                },
            },
            {
                name: '增幅',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: [
                    0.3,
                    0.4,
                    0.5,
                    0.4,
                    0.6,
                    0.7,
                    0.4,
                    0.7,
                    0.3,
                    0.6,
                    0.5
                ],
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.8)',
                }
            }
        ]
    };
};
export default chart1
