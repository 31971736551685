import * as echarts from 'echarts';
import 'echarts-gl';
import ChinaGeoJSON from '../../../GeoJSON/china.json'

const chinaMap = () => {
    // 地图数据来自 https://datav.aliyun.com/portal/school/atlas/area_selector
    // DataV GeoAtlas
    const buildingsGeoJSON: any = ChinaGeoJSON
    const buildingsLine = buildingsGeoJSON.features
        .filter((it: any) => {
            return !!it.properties.center;

        })
        .map((it: any) => {
            return [
                it.properties.center, // 终点的经纬度和海拔坐标
                [118.343325, 39.71703], // 起点的经纬度和海拔坐标 这里是唐山的坐标
            ]
        })
    echarts.registerMap('china-new', buildingsGeoJSON);

    return {
        geo3D: {
            map: "china-new",
            boxWidth: 74,
            regionHeight: 2,
            viewControl: {
                zoomSensitivity: 0, // 禁用缩放
                alpha: 60, // 倾斜角
                maxAlpha: 60, // 倾斜角
                minAlpha: 60, // 倾斜角
                beta: -10, // 倾斜角
                maxBeta: -10, // 倾斜角
                minBeta: -10, // 倾斜角
            },
            label: {
                show: false,
                color: 'rgba(255,255,255,0)',
                formatter: (params: any) => params.name ? params.name : ' '
            },
            itemStyle: {
                color: 'rgba(35, 183, 229, 0.8)',
                borderColor: 'rgba(35, 183, 229, 0.6)',
                backgroundColor: 'rgba(35, 183, 229, 0.3)',
                borderWidth: 1,
                opacity: 0.4,
            },
            emphasis: {
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.8)',
                    borderColor: 'rgba(35, 183, 229, 0.6)',
                    backgroundColor: 'rgba(35, 183, 229, 0.3)',
                    borderWidth: 1,
                    opacity: 0.4,
                }
            },
            light: {
                ambient: {
                    intensity: -0.1
                },
            },
        },
        series: [
            {
                // 这组内容是为了支持点击事件，geo3D 貌似点击事件困难，因为地图是不规则图形
                // 所以叠加一个一样姿势的透明地图承担点击事件
                // 这个地图不知道如何与飞线结合，所以用了这个方法。
                type: 'map3D',
                map: "china-new",
                boxWidth: 74,
                label: {
                    show: false,
                    color: 'rgba(255,255,255,0)',
                    formatter: (params: any) => params.name ? params.name : ' '
                },
                viewControl: {
                    zoomSensitivity: 0, // 禁用缩放
                    alpha: 60, // 倾斜角
                    maxAlpha: 60, // 倾斜角
                    minAlpha: 60, // 倾斜角
                    beta: -10, // 倾斜角
                    maxBeta: -10, // 倾斜角
                    minBeta: -10, // 倾斜角
                },
                itemStyle: {
                    opacity: 0,
                },
            },
            {
                type: 'lines3D',
                coordinateSystem: 'geo3D',
                effect: {
                    show: true,
                    period: 4, // 图标飞跃速度，值越小速度越快
                    trailLength: 0.2, // 尾迹长度[0,1]值越大，尾迹越长
                    trailColor: 'rgba(245,243,179,0.8)',
                    trailWidth: '2',
                },
                lineStyle: {
                    width: 1,
                    color: 'rgba(35, 183, 229, 0.8)',
                    opacity: 1,
                },//3D飞线图
                data: buildingsLine
            },
            // 中心点设置
            {
                type: "scatter3D",
                coordinateSystem: 'geo3D',
                symbol: "circle", // 图标样式
                symbolSize: 80,
                itemStyle: {
                    color: "rgba(35, 183, 229, 0.6)",
                },
                // 中心点数据
                data: [
                    [118.343325, 39.71703]
                ],
            },
        ]
    };
};
export default chinaMap
