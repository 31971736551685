import React, { useEffect, useRef, useState } from 'react';
import style from './PageDrill6.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import { useLocation } from "react-router-dom";
import { ScrollBoard } from '@jiaminghi/data-view-react'
import { getEnterpriseList, getJobList } from "../../api"

interface PageDrill6Props {
    width: number;
    height: number;
}

const PageDrill6: React.FC<PageDrill6Props> = (props) => {
    const { width, height } = props

    const location = useLocation();
    const region_id = (new URLSearchParams(location.search)).get('region_id') || '55';
    const [scrollBoardConfig, setScrollBoardConfig] = useState<any>([]);
    const [scrollBoardConfig2, setScrollBoardConfig2] = useState<any>([]);

    // 获取企业列表
    const getEnterpriseListFun = async () => {
        const ctx: any = await getEnterpriseList({ limit: 200, county_id: region_id });
        let enterpriseData: any = [];
        const enterprise = ctx.data.enterprise;
        for (let i = 0; i < enterprise.length; i++) {
            enterpriseData.push([enterprise[i].enterprise_name]);
        }
        const scrollBoardConfig1 = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['企业名称'],
            rowNum: 15,
            data: enterpriseData
        }
        setScrollBoardConfig(scrollBoardConfig1)
    };
    //获取职位列表
    const getJobListFun = async () => {
        const ctx: any = await getJobList({ limit: 200, county_id: region_id });
        let jobData: any = [];
        const job = ctx.data.job;
        for (let i = 0; i < job.length; i++) {
            jobData.push([job[i].enterprise_name, job[i].job_name]);
        }
        const scrollBoardConfig = {
            headerBGC: 'rgba(35, 183, 229, 0.3)',
            oddRowBGC: 'rgba(35, 183, 229, 0.1)',
            evenRowBGC: 'rgba(0, 0, 0, 0.0)',
            header: ['企业名称', '职位名称'],
            rowNum: 15,
            data: jobData
        }
        setScrollBoardConfig2(scrollBoardConfig)
    };
    useEffect(() => {
        getEnterpriseListFun();
        getJobListFun();
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1} />
            </div>

            {/* 最新企业 */}
            <div style={{ position: 'absolute', left: '55px', top: '55px', }}>
                <RedDotTitle title="最新企业" />
            </div>
            {/* 企业列表-表格 */}
            <div style={{ position: 'absolute', left: '55px', top: '100px', }}>
                <ScrollBoard config={scrollBoardConfig} style={{ width: '560px', height: '680px' }}/>
                <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'} right={'0'}
                    bottom={'-30px'} />
            </div>
            {/* 最新职位 */}
            <div style={{ position: 'absolute', left: '665px', top: '55px', }}>
                <RedDotTitle title="最新职位" />
            </div>
            {/* 职位列表-表格 */}
            <div style={{ position: 'absolute', left: '665px', top: '100px', }}>
                <ScrollBoard config={scrollBoardConfig2} style={{ width: '560px', height: '680px' }}/>
                <FootNote title={'数据来源：唐山人才市场小程序'} link={'http://wx.tsrc.net.cn/admin'} right={'0'}
                    bottom={'-30px'} />
            </div>
        </PageBox>
    );
}

export default PageDrill6;
