import * as echarts from 'echarts';
import 'echarts-gl';
import ChinaGeoJSON from '../../../GeoJSON/china.json'

const chinaMap1 = () => {
    // 地图数据来自 https://datav.aliyun.com/portal/school/atlas/area_selector
    // DataV GeoAtlas
    const buildingsGeoJSON: any = ChinaGeoJSON
    const buildingsLine = buildingsGeoJSON.features
        .filter((it: any) => {
            return !!it.properties.center;

        })
        .map((it: any) => {
            return [
                it.properties.center, // 终点的经纬度和海拔坐标
                [116.4551, 40.2539], // 起点的经纬度和海拔坐标
            ]
        })
    echarts.registerMap('china', buildingsGeoJSON);

    return {
        // 设置地图样式
        geo: {
            map: "china",
            zoom: 1.2,
            layoutSize: "100%", //保持地图宽高比
            label: {
                // 默认样式
                normal: {
                    show: true,
                    fontSize: "14",
                    color: "rgba(255,255,255,.3)",
                },
                // 高亮样式
                emphasis: {
                    show: true,
                    textStyle: {
                        color: "#1955a4",
                    },
                },
            },
            itemStyle: {
                // 默认样式，图块没数据时，会取默认颜色
                normal: {
                    borderColor: "#c0e0e3",
                    areaColor: "#29c5f6",
                    opacity: 0.8,
                },
                // 高亮样式
                emphasis: {
                    areaColor: "#f2d5ad",
                },
            },
        },
        series: [
            // 设置飞线样式
            {
                type: "lines",
                coordinateSystem: "geo",
                zlevel: 100,
                effect: {
                    show: true,
                    period: 4, // 图标飞跃速度，值越小速度越快
                    trailLength: 0.2, // 尾迹长度[0,1]值越大，尾迹越长
                    symbol: "pin", // 图标类型
                    symbolSize: 4, // 图标大小
                    color: "#f5f3b3", // 图标颜色
                },
                lineStyle: {
                    color: "#000",
                    normal: {
                        show: true,
                        width: 1, //尾迹线条宽度
                        opacity: 0.1, //尾迹线条透明度
                        curveness: 0.3, //尾迹线条曲直度
                        color: "#44add00f", // 飞线颜色
                    },
                },
                // 飞线数据
                data: buildingsLine,
            },
            // 中心点设置
            {
                type: "effectScatter",
                coordinateSystem: "geo",
                zlevel: 15,
                rippleEffect: {
                    period: 3,
                    brushType: "fill", // 动画样式 fill stroke
                    scale: 60,
                    color: "#7ce7fd",
                    number: 2,
                },
                symbol: "circle", // 图标样式
                symbolSize: 2,
                itemStyle: {
                    color: "#5cc8d4",
                },
                // 中心点数据
                data: [
                    {
                        name: "北京市",
                        value: [116.4551, 40.2539],
                    },
                ],
            },
        ],
    };
};
export default chinaMap1
