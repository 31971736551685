import DataStore from '../data/产业人才.json'

const chart1 = (region_name: string) => {
    const data = DataStore.filter((it) => {
        return it.region === region_name
    })[0]

    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            data: ['第一产业', '第二产业', '第三产业',]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
        },
        series: [
            {
                type: 'bar',
                label: {
                    show: true,
                    position: 'top',
                    color: 'inherit',
                    fontWeight: 'bold',
                },
                data: [
                    {
                        value: data['top1'],
                        itemStyle: {
                            color: '#724e58'
                        }
                    },
                    {
                        value: data['top2'],
                        itemStyle: {
                            color: '#6e7074'
                        }
                    },
                    {
                        value: data['top3'],
                        itemStyle: {
                            color: '#61a0a8'
                        }
                    },],
            }
        ]
    };
};
export default chart1
