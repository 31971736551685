import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import {ScrollBoard} from "@jiaminghi/data-view-react";
import style from './Page5.module.css';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chart1 from "./components/chart1";
import CarouselPage from "./components/carousel";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import chart4 from "./components/chart4";
import Decoration from "../../components/Decoration";

interface Page5Props {
    width: number;
    height: number;
}

const Page5: React.FC<Page5Props> = (props) => {
    const {width, height} = props

    const scrollBoardConfig1 = {
        headerBGC: 'rgba(35, 183, 229, 0.3)',
        oddRowBGC: 'rgba(35, 183, 229, 0.1)',
        evenRowBGC: 'rgba(0, 0, 0, 0.0)',
        header: ['培训会名称'],
        rowNum: 6,
        data: [
            ['智慧创造价值· 创新点亮未来—唐山市开平区重点产业高端人才技术项目对接会',],
            ['新视野· 新动能· 新辉煌－企业家浙江行',],
            ['人才赋能· 智汇未来－2023 唐山钢铁产业技术项目成果发布会',],
            ['2023 年中关村科技人才‘一对一’ 服务唐山行',],
            ['中国农业科学院科技服务团唐山行',],
            ['中国农业科学院高端人才唐山行暨乡村产业发展战略学研究会揭牌、签约仪式',],
            ['2023 年全国职业经理人考试测评标准化技术委员会年度工作会议',],
            ['中国职业经理人大讲堂（河北峰会）',],
            ['高级职业经理资质培训与评价专题培训班',],
            ['2023 年迁安市生物医药与健康养殖产业人才论坛',],
            ['唐山人才大道项目在第二届全国人力资源服务业发展大会上正式签约',],
            ['唐山市2023年北京大学、吉林大学“名校进唐山”莘莘学子暑期社会实践活动',],
            ['2023年中关村科技人才“一对一”服务唐山行——瑞丰钢铁专场活动',],
            ['创新引领·人才赋能—2023唐山市重点产业高端人才技术项目对接会',],
            ['2023年中关村科技人才“一对一”服务唐山行活动”首钢京唐专场',],
            ['河北省第一届职业技能大赛',],
            ['惠达之光2023高校毕业生就业论坛——百所高校进唐山活动',],
        ],
    }

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    let _Chart3: EChartsType
    const domRefChart3: any = useRef();
    let _Chart4: EChartsType
    const domRefChart4: any = useRef();

    const chartsInit = () => {

        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current, 'vintage');
        _Chart2.setOption(chart2());

        if (_Chart3) _Chart3.dispose()
        _Chart3 = echarts.init(domRefChart3.current, 'vintage');
        _Chart3.setOption(chart3());

        if (_Chart4) _Chart4.dispose()
        _Chart4 = echarts.init(domRefChart4.current);
        _Chart4.setOption(chart4());
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox width={width} height={height}>
                <div className={style.background}></div>
                <div style={{
                    position: 'absolute', left: '1125px', top: '515px',
                    width: '400px', height: '400px',
                }}>
                    <Decoration opacity={0.1}/>
                </div>
                <Headline title={'创新赋能（留）'} fontSize="46" type={'text'}/>

                {/* 汇总指标 */}
                <div style={{position: 'absolute', left: '50px', top: '120px',}}>
                    <RedDotTitle title={'指标数据'}/>
                </div>
                {/* 汇总指标 */}
                <div style={{position: 'absolute', left: '50px', top: '160px',}}>
                    <Space
                        style={{
                            width: '740px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '全年服务项目', value: '85场',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '活动参与人数', value: '85,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
                </div>

                {/* 考试培训场次 */}
                <div style={{position: 'absolute', left: '875px', top: '120px',}}>
                    <RedDotTitle title={'创新平台'}/>
                </div>
                {/* 考试培训场次 */}
                <div style={{position: 'absolute', left: '875px', top: '160px',}}>
                    <Space
                        style={{
                            width: '1005px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: '市级', value: '207',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: '省级', value: '122',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                            {
                                title: '自主立项', value: '3',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                            {
                                title: '国家级', value: '2',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
                </div>

                {/* 培训会汇总 */}
                <div style={{position: 'absolute', left: '50px', top: '295px',}}>
                    <RedDotTitle title={'培训会汇总'}/>
                </div>
                {/* 培训会汇总-表格 */}
                <div style={{position: 'absolute', left: '50px', top: '345px',}}>
                    <ScrollBoard config={scrollBoardConfig1} style={{width: '740px', height: '320px'}}/>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}
                              bottom={'-30px'}/>
                </div>

                <div style={{position: 'absolute', left: '870px', top: '300px',}}>
                    <div style={{
                        width: '520px', height: '200px',
                    }}
                         ref={domRefChart2}
                    />
                </div>
                <div style={{position: 'absolute', left: '1400px', top: '300px',}}>
                    <div style={{
                        width: '500px', height: '200px',
                    }}
                         ref={domRefChart3}
                    />
                </div>
                <div style={{position: 'absolute', left: '840px', top: '500px',}}>
                    <div style={{
                        width: '580px', height: '290px',
                    }}
                         ref={domRefChart1}
                    />
                </div>
                <div style={{position: 'absolute', left: '1400px', top: '500px',}}>
                    <div style={{
                        width: '485px', height: '290px',
                    }}
                         ref={domRefChart4}
                    />
                </div>

                {/* 服务项目展示 */}
                <div style={{position: 'absolute', left: '50px', top: '720px',}}>
                    <RedDotTitle title={'服务项目展示'}/>
                    <FootNote title={'数据来源：唐山人才集团公众号'} link={''}
                              bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
                </div>
                <div style={{position: 'absolute', left: '0', top: '800px',}}>
                    <div style={{
                        width: '1920px', height: '260px',
                    }}>
                        <CarouselPage/>
                    </div>
                </div>
            </PageBox>
        </>
    );
}

export default Page5;
