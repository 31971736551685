const chart3 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: [
            {
                type: 'category',
                axisLabel: {
                    interval: 0,
                    rotate: -25,
                },
                data: [
                    '遵化',
                    '迁安',
                    '滦州',
                    '玉田',
                    '迁西',
                    '滦南',
                    '乐亭',
                    '曹妃甸',
                    '丰南',
                    '丰润',
                    '路北',
                    '路南',
                    '开平',
                    '古冶',
                    '高新',
                    '海港',
                    '芦台',
                    '汉沽',
                    '国有企业',
                    '教育系统',
                    '卫生系统',
                    '金融系统',
                    '市本级',
                ],
                axisLine: {
                    show: true,
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            },
            {
                type: 'value',
                name: '',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
            },
        ],
        series: [
            {
                name: '2023',
                type: 'bar',
                data: [
                    4930,
                    4875,
                    3725,
                    2287,
                    2159,
                    2105,
                    2516,
                    4264,
                    9994,
                    5164,
                    6017,
                    3846,
                    3775,
                    1372,
                    1636,
                    897,
                    603,
                    487,
                    3863,
                    1086,
                    642,
                    552,
                    598,
                ],
                barWidth: 10,
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
            {
                name: '2022',
                type: 'bar',
                data: [
                    6862,
                    13908,
                    7721,
                    8192,
                    6256,
                    7060,
                    6802,
                    10531,
                    10529,
                    9281,
                    9492,
                    6907,
                    4463,
                    5912,
                    3680,
                    2784,
                    1028,
                    1611,
                    2025,
                    178,
                    1863,
                    550,
                    401,
                ],
                barWidth: 10, // 柱状图宽度
                yAxisIndex: 1,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.3)',
                },
            }
        ]
    };
};
export default chart3
