const chart2 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                ['华北理工'],
                ['华理冀唐'],
                ['华理轻工'],
                ['华理迁安'],
                ['唐山师范'],
                ['唐山学院'],
                ['河北能源'],
                ['唐山科职'],
                ['唐山职院'],
                ['唐山工职'],
                ['曹职学院'],
                ['唐山海运'],
                ['唐山幼专'],
                ['唐幼玉田'],
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: [
                    61.48,
                    80.17,
                    82.67,
                    83.92,
                    85.01,
                    83.22,
                    95.35,
                    85.66,
                    92.12,
                    98.75,
                    90.12,
                    83.73,
                    87.68,
                    83.14,
                ],
            }
        ]
    };
};
export default chart2
