import * as echarts from 'echarts';
import 'echarts-gl';
import HebeiTangshanGeoJSON from '../../../GeoJSON/hebei_tangshan.json'
import DataStore from "../../page_drill_1/data/企业人才数.json";

const hebeiTangshanMap = () => {
    // 地图数据来自 https://datav.aliyun.com/portal/school/atlas/area_selector
    // DataV GeoAtlas
    const buildingsGeoJSON: any = HebeiTangshanGeoJSON
    echarts.registerMap('hebei_tangshan', buildingsGeoJSON);

    // const regions: any = [];
    const regions = buildingsGeoJSON.features.map((feature: any) => {
        const data = DataStore.filter((it: any) => {
            return it.region === feature.properties.name
        })[0]
        return {
            name: feature.properties.name,
            value: 1, // 有这一行，可以让这个图层展示，具体展示的内容，交稿给 tooltip.formatter 自定义
            values: [
                {
                    name: '企业数',
                    value: data['企业数']||'-',
                },
                {
                    name: '人才数',
                    value: data['人才数']||'-',
                },
            ],
            height: 2
        };
    });

    return {
        tooltip: {
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
            formatter:function (params:any) {
                return `${params.data.name||'-'}<br/>`+ params.data.values.map((it:any)=>{
                    return `${it.name} ${it.value}`
                }).join('<br/>')
            }
        },
        visualMap: {
            show: false,
            left: 'right',
            min: 500000,
            max: 38000000,
            inRange: {
                color: [
                    'rgba(35, 183, 229, 0.8)',
                ]
            },
            text: ['High', 'Low'],
            calculable: true
        },
        series: [
            {
                name: '人口数量',
                type: 'map3D',
                map: 'hebei_tangshan',
                boxWidth: 76,
                viewControl: {
                    zoomSensitivity: 0, // 禁用缩放
                    alpha: 50, // 倾斜角
                    maxAlpha: 50, // 倾斜角
                    minAlpha: 50, // 倾斜角
                    beta: 0, // 倾斜角
                    maxBeta: 0, // 倾斜角
                    minBeta: 0, // 倾斜角
                },
                label: {
                    show: true,
                    color: '#FFF',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
                itemStyle: {
                    borderColor: 'rgba(35, 183, 229, 0.8)',
                    backgroundColor: 'rgba(35, 183, 229, 0.6)',
                    borderWidth: 1,
                    opacity: 0.4,
                },
                light: {
                    ambient: {
                        intensity: -0.1
                    },
                },
                data: regions
            }
        ]
    };
};
export default hebeiTangshanMap
