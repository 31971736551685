import React, {CSSProperties} from "react";

interface RedDotTitleProps {
    title: string;
    style?: CSSProperties | undefined;
}

const RedDotTitle: React.FC<RedDotTitleProps> = (props) => {
    const {title, style} = props
    return <div style={style}>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '35px',
                lineHeight: '35px',
            }}
        >
            <div
                style={{
                    width:'28px',
                    aspectRatio: 1,
                    background: '#f05050',
                    borderRadius: '50%',
                    display: 'inline-block',
                }}
            />
            <span style={{padding: '0 4px', color: 'white', fontSize: '28px'}}>{title}</span>
        </div>
    </div>
}
export default RedDotTitle