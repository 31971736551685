const chart4 = () => {
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        radar: {
            // shape: 'circle',
            startAngle: 0,
            axisLine: {
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.8)'
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.3)'
                }
            },
            axisName: {
                fontSize: '18px',
            },
            indicator: [
                {
                    text: "技术创新中心",
                    max: 200
                },
                {
                    text: "重点实验室",
                    max: 100
                },
                {
                    text: "产业技术研究院",
                    max: 100
                },
                {
                    text: "其他",
                    max: 30
                },
                {
                    text: "新型研发机构",
                    max: 10
                },
                {
                    text: "共建科技合作示范基地",
                    max: 3
                }
            ]
        },
        series: [
            {
                type: 'radar',
                data: [
                    {
                        name: "平台类别",
                        itemStyle: {
                            color: 'rgba(35, 183, 229, 0.6)',
                        },
                        value: [
                            195,
                            90,
                            24,
                            17,
                            6,
                            2
                        ]
                    }
                ]
            }
        ]
    };
};
export default chart4
