import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import style from './PageDrill2.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import FootNote from "../../components/FootNote";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";

interface PageDrill1Props {
    width: number;
    height: number;
}

const PageDrill2: React.FC<PageDrill1Props> = (props) => {
    const {width, height} = props

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    let _Chart3: EChartsType
    const domRefChart3: any = useRef();

    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current);
        _Chart2.setOption(chart2());

        if (_Chart3) _Chart3.dispose()
        _Chart3 = echarts.init(domRefChart3.current);
        _Chart3.setOption(chart3());
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1}/>
            </div>

            <div style={{
                position: 'absolute', left: '490px', top: '420px',
                width: '361px', aspectRatio: 1, opacity: 0.1,
            }}>
                <div className={style.pic1}/>
            </div>

            {/* 汇总指标 */}
            <div style={{position: 'absolute', left: '55px', top: '55px',}}>
                <RedDotTitle title={`人才留唐`}/>
            </div>
            <div style={{position: 'absolute', left: '55px', top: '110px',}}>
                <Space
                    style={{
                        width: '600px', height: '110px',
                        display: 'flex', flexFlow: 'row',
                        overflow: 'hidden',
                    }}
                    styles={{item: {flex: 1,}}}
                >
                    {[
                        {
                            title: '省内', value: '41,100',
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.8))',
                        },
                        {
                            title: '全国', value: '143,332',
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.6))',
                        },
                    ].map((it, index) => (
                        <Card
                            key={index}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                background: it.gradient,
                                color: 'white',
                            }}
                            bodyStyle={{padding: '8px'}}
                            bordered={false}
                        >
                            <div style={{fontSize: '32px'}}>{it.value}</div>
                            <div style={{fontSize: '16px'}}>{it.title}</div>
                        </Card>
                    ))}
                </Space>
                <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                          right={'0'}/>
            </div>

            <div style={{position: 'absolute', left: '55px', top: '300px',}}>
                <div style={{
                    width: '650px', height: '280px',
                }}
                     ref={domRefChart1}
                />
            </div>
            <div style={{position: 'absolute', left: '55px', top: '260px',}}>
                <RedDotTitle title={'县区TOP10'}/>
                <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                          bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
            </div>

            <div style={{position: 'absolute', left: '680px', top: '140px',}}>
                <div style={{
                    width: '550px', height: '410px',
                }}
                     ref={domRefChart2}
                />
            </div>
            <div style={{position: 'absolute', left: '700px', top: '55px',}}>
                <RedDotTitle title={'职业TOP10'}/>
                <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                          bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
            </div>


            <div style={{position: 'absolute', left: '55px', top: '580px',}}>
                <div style={{
                    width: '1200px', height: '240px',
                }}
                     ref={domRefChart3}
                />
            </div>
            <div style={{position: 'absolute', left: '55px', top: '560px',}}>
                <RedDotTitle title={'高校TOP30'}/>
                <FootNote title={'数据来源：高校毕业生统计分析平台'} link={'http://tsrsdv.qinghuiche.com/'}
                          bottom={'-30px'} left={'30px'} width={'500px'} textAlign={'start'}/>
            </div>
        </PageBox>
    );
}

export default PageDrill2;
