import React from 'react';
import './Headline.scss'

interface HeadlineProps {
    title: string,
    fontSize: string,
    type: string
}

const Headline: React.FC<HeadlineProps> = (props) => {
    const { title, type} = props
    const style = {
        fontSize: props.fontSize + 'px',
        LineHeight: props.fontSize + 'px',
    }
    return (
        <div id={'headline_box'}>
            <div style={{
                position: 'absolute', left: '0px', top: '5px', width: '100%',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
            }}>
                <div className={'headline_bkg'} style={{ width: '1260px', height: '115px' }} />
            </div>
            <div style={{
                position: 'absolute', left: '0px', top: '15px', width: '100%',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',

            }}>{
                    type == 'text' ? (<div className={'headline'} style={style}>{title}</div>) : (<img src={require('./image/title9.png')} alt="" />)
                }
            </div>
        </div>
    );
}

export default Headline;
