import React, { useEffect, useState } from 'react';
import style from './PageDrill3.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import { useLocation } from "react-router-dom";
import { getEnterpriseData } from "../../api"

interface PageDrill3Props {
    width: number;
    height: number;
}

const PageDrill3: React.FC<PageDrill3Props> = (props) => {
    const { width, height } = props
    const [enterpriseData, setEnterpriseData] = useState<any>({});
    const [enterpriseImage, setEnterpriseImage] = useState<any>([])
    const location = useLocation();
    const region_id = (new URLSearchParams(location.search)).get('region_id') || '4253';
    const getEnterpriseDataFun = async () => {
        const ctx = await getEnterpriseData({ enterprise_id: region_id })
        setEnterpriseData(ctx.data.enterprise)
        if (ctx.data.enterprise.enterprise_image) {
            const image = ctx.data.enterprise.enterprise_image.split(",");
            console.log('image', image)
            setEnterpriseImage(image)
        }
    }
    useEffect(() => {
        getEnterpriseDataFun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1} />
            </div>
            <div className={style.scrollBar}>

                <div style={{ fontSize: '28px', color: '#fff' }}>{enterpriseData.enterprise_name}</div>

                <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px', marginTop: '20px' }}>
                    {enterpriseData.industry_name}&nbsp;&nbsp;
            {(enterpriseData.scale_name && enterpriseData.industry_name) ? "|" : ""}&nbsp;&nbsp;
            {enterpriseData.scale_name}</div>
                <div style={{ marginTop: '30px' }}>
                    <div>
                        <RedDotTitle title={`企业地址`} />
                    </div>
                    <div style={{ color: '#fff', fontSize: '22px', lineHeight: '34px', marginTop: '30px' }}>{enterpriseData.enterprise_address}</div>
                    {enterpriseImage.length > 0 ? <>
                        <div style={{ marginTop: '30px' }}>
                            <RedDotTitle title={`企业照片`} />
                        </div>
                        <div className={style.picBox} style={{ marginTop: '30px' }}>
                            {enterpriseImage.map((item: any, index: any) => {
                                return (
                                    <img src={item} alt={item} key={index} />
                                );
                            })}
                        </div>
                    </> : <></>}
                    {/* 企业简介 */}
                    {enterpriseData.enterprise_introduction ? <>
                        <div style={{ marginTop: '30px' }}>
                            <RedDotTitle title={`企业简介`} />
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <div style={{ color: '#fff', fontSize: '18px', lineHeight: '34px', width: '1200px' }}>{enterpriseData.enterprise_introduction}</div>
                        </div>
                    </> : <></>}
                </div>

            </div>
        </PageBox>
    );
}

export default PageDrill3;
