const chart3 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: { // 控制表格位置
            x: '40px',
            x2: '60px',
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                '唐山工业职业技术学院',
                '唐山学院',
                '唐山职业技术学院',
                '唐山师范学院',
                '河北能源职业技术学院',
                '华北理工大学',
                '曹妃甸职业技术学院',
                '河北农业大学',
                '河北师范大学',
                '唐山科技职业技术学院',
                '燕京理工学院',
                '河北北方学院',
                '河北科技师范学院',
                '华北理工大学轻工学院',
                '河北建材职业技术学院',
                '河北科技学院',
                '河北大学',
                '邢台学院',
                '河北民族师范学院',
                '国家开放大学',
                '河北经贸大学',
                '渤海理工职业学院',
                '衡水学院',
                '邯郸学院',
                '秦皇岛职业技术学院',
                '唐山工程技术学院',
                '河北科技大学',
                '保定学院',
                '廊坊师范学院',
                '沧州师范学院',
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: [
                    6873,
                    6547,
                    6536,
                    5570,
                    4148,
                    3694,
                    3297,
                    2502,
                    1918,
                    1899,
                    1816,
                    1788,
                    1742,
                    1719,
                    1475,
                    1384,
                    1373,
                    1371,
                    1369,
                    1364,
                    1339,
                    1291,
                    1285,
                    1256,
                    1247,
                    1247,
                    1240,
                    1206,
                    1175,
                    1128,
                ],
            }
        ]
    };
};
export default chart3
