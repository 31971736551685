const chart2 = () => {
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['70%', '80%'],
                startAngle: 85,
                itemStyle: {
                    borderRadius: 20,
                },
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    margin: 10,
                    textStyle: {
                        color: 'inherit', // 继承颜色
                        fontSize: '18px',
                    },
                    formatter: (params: any) => {
                        return `${params.name || '-'}: ${params.value}`
                    }
                },
                data: [
                    {
                        "name": "本科",
                        "value": 3174
                    },
                    {
                        "name": "硕士研究生",
                        "value": 1658
                    },
                    {
                        "name": "博士研究生",
                        "value": 1432
                    },
                    {
                        "name": "大专",
                        "value": 1092
                    },
                    {
                        "name": "其他",
                        "value": 436
                    },
                    {
                        "name": "中专（高中）及以下",
                        "value": 226
                    },
                    {
                        "name": "未采集",
                        "value": 10
                    }
                ]
            }
        ]
    };
};
export default chart2
