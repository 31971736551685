const chart1 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
            formatter:  (params: any)=> {
                return `${params[0].seriesName || '-'} ${params[0].value || '-'}`
            }
        },
        xAxis: [
            {
                type: 'category',
                data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月"
                ],
                axisLine: {
                    show: true,
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '今年',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    }
                },
            },
            {
                type: 'value',
                name: '今年',
                axisLine: {
                    show: true,
                },
                splitLine: { // 分隔线
                    show: false,
                },
            },
        ],
        series: [
            {
                name: '学习时长',
                type: 'bar',
                data: [
                    345,
                    444,
                    654,
                    786,
                    444,
                    654,
                    786,
                    444,
                    654,
                    786,
                    1001
                ],
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
            {
                name: '增幅',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: [
                    345,
                    444,
                    654,
                    786,
                    444,
                    654,
                    786,
                    444,
                    654,
                    786,
                    1001
                ],
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.8)',
                }
            }
        ]
    };
};
export default chart1
