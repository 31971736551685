import axios from 'axios';

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: 'https://wx.tsrc.net.cn',
  timeout: 6000, // 请求超时时间
});
// 异常拦截处理器
const errorHandler = (error:any) => {
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (config:any) => {
  return config;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (response:any) => {
  return response.data;
};

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);


export default request;  
