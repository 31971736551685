const chart3 = () => {
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['70%', '80%'],
                startAngle: 0,
                itemStyle: {
                    borderRadius: 20,
                },
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    margin: 10,
                    textStyle: {
                        color: 'inherit', // 继承颜色
                        fontSize: '18px',
                    },
                    formatter: (params: any) => {
                        return `${params.name || '-'}: ${params.value}`
                    }
                },
                data: [
                    {
                        "name": "国家级",
                        "value": 142
                    },
                    {
                        "name": "省级",
                        "value": 452
                    },
                    {
                        "name": "市级",
                        "value": 496
                    },
                    {
                        "name": "自立项目",
                        "value": 1893
                    }
                ]
            }
        ]
    };
};
export default chart3
