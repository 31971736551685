const chart1 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid:{
          top:18,
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                "制造业",
                "其他",
                "科学、技术",
                "农林牧渔",
                "信息、计算、软件",
                "建筑业",
                "居民和其他",
                "采矿业",
                "电力、燃气",
                "水利、环境",
                "交通、仓储"
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: [
                    4468,
                    1356,
                    770,
                    658,
                    240,
                    185,
                    185,
                    64,
                    40,
                    38,
                    24
                ],
            }
        ]
    };
};
export default chart1
