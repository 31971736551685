import request from './request';
export async function getEnterpriseLogoList(params:any) {
    return request.post('/tool/tsrcdv/getEnterpriseLogoList', params);
}
export async function getEnterpriseList(params:any) {
    return request.post('/tool/tsrcdv/getEnterpriseList', params);
}
export async function getEnterpriseData(params:any) {
    return request.post('/tool/tsrcdv/getEnterpriseData', params);
}
export async function getJobList(params:any) {
    return request.post('/tool/tsrcdv/getJobList', params);
}
export async function getJobData(params:any) {
    return request.post('/tool/tsrcdv/getJobData', params);
}
export async function getStatisticsData(params:any) {
    return request.post('/tool/tsrcdv/getStatisticsData', params);
}
export async function getEducationStatisticsList(params:any) {
    return request.post('/tool/tsrcdv/getEducationStatisticsList', params);
}
export async function getIndustryStatisticsList(params:any) {
    return request.post('/tool/tsrcdv/getIndustryStatisticsList', params);
}
export async function getJobStatisticsList(params:any) {
    return request.post('/tool/tsrcdv/getJobStatisticsList', params);
}
export async function getCountyStatisticsList(params:any) {
    return request.post('/tool/tsrcdv/getCountyStatisticsList', params);
}
export async function getResumeList(params:any) {
    return request.post('/tool/tsrcdv/getResumeList', params);
}
export async function getResumeData(params:any) {
    return request.post('/tool/tsrcdv/getResumeData', params);
}