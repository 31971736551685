import React, {useEffect, useState, useRef} from 'react';
import {Card, Modal, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import './Page1.scss';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import hebeiTangshanMap from "./components/hebei_tangshan_map";
import Headline from "../../components/Headline";
import FootNote from "../../components/FootNote";
import TileBackground from "../../components/TileBackground";
import chart1 from "./components/chart1";

interface Page1Props {
    width: number;
    height: number;
}

const Page1: React.FC<Page1Props> = (props) => {
    const {width, height} = props

    const [appBaseURL, setAppBaseURL] = useState('');
    const [isModalOpen, setIsModalOpen] = useState([false, '']);
    const hideModal = () => {
        setIsModalOpen([false, '']);
    };

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current);
        _Chart1.setOption(chart1());

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current);
        _Chart2.setOption(hebeiTangshanMap());
        _Chart2.off('click') // 因为这个函数可能触发多次，需要先清除点击事件，避免重复触发
        _Chart2.on('click', (params: any) => {
            setIsModalOpen([true, params.data.name])
        })
    };
    useEffect(() => {
        chartsInit();
        setAppBaseURL((process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PRO) || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox id={'Page1'} width={width} height={height}>
                <Headline title={'唐山人才大数据共享平台'} fontSize="46" type={'text'}/>

                {/* 唐山人才地图 */}
                <div style={{position: 'absolute', left: '50px', top: '125px',}}>
                    <RedDotTitle title={'唐山人才地图'}/>
                </div>
                <div style={{position: 'absolute', left: '200px', top: '-75px',}}>
                    <div ref={domRefChart2} style={{width: '800px', height: '800px',}}/>
                </div>

                {/* 人才数据存量增长分析-这线图 */}
                <div style={{position: 'absolute', left: '0px', top: '680px',}}>
                    <div ref={domRefChart1} style={{width: '900px', height: '400px',}}/>
                </div>
                {/* 人才数据存量增长分析 */}
                <div style={{position: 'absolute', left: '50px', top: '640px',}}>
                    <RedDotTitle title={'人才数据存量增长分析'}/>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'}
                              bottom={'-30px'} left={'30px'}/>
                </div>

                {/* 收录单位汇总 */}
                <div style={{position: 'absolute', left: '930px', top: '125px',}}>
                    <RedDotTitle title={'收录单位汇总'}/>
                </div>
                <div style={{position: 'absolute', left: '930px', top: '165px',}}>
                    <Space
                        style={{
                            width: '290px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: <>增幅 <span style={{margin: '0 8px',}}>
                                <span style={{
                                    transformOrigin: '50% 30%',
                                    transform: 'rotate(180deg)',
                                    borderTop: '6px solid',
                                    borderRight: '4px solid transparent',
                                    borderLeft: '4px solid transparent',
                                    display: 'inline-block',
                                }}/> 3.34%</span></>, value: '54,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                </div>

                {/* 本地人才总量 */}
                <div style={{position: 'absolute', left: '1250px', top: '125px',}}>
                    <RedDotTitle title={'本地人才总量'}/>
                </div>
                <div style={{position: 'absolute', left: '1250px', top: '165px',}}>

                    <Space
                        style={{
                            width: '290px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: <>增幅 <span style={{margin: '0 8px',}}>
                                <span style={{
                                    transformOrigin: '50% 30%',
                                    transform: 'rotate(180deg)',
                                    borderTop: '6px solid',
                                    borderRight: '4px solid transparent',
                                    borderLeft: '4px solid transparent',
                                    display: 'inline-block',
                                }}/> 4.14%</span></>, value: '1,353,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                </div>

                {/* 唐山籍在外汇总 */}
                <div style={{position: 'absolute', left: '1570px', top: '125px',}}>
                    <RedDotTitle title={'唐山籍在外汇总'}/>
                </div>
                <div style={{position: 'absolute', left: '1570px', top: '165px',}}>
                    <Space
                        style={{
                            width: '290px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: <>增幅 <span style={{margin: '0 8px',}}>
                                <span style={{
                                    transformOrigin: '50% 30%',
                                    transform: 'rotate(180deg)',
                                    borderTop: '6px solid',
                                    borderRight: '4px solid transparent',
                                    borderLeft: '4px solid transparent',
                                    display: 'inline-block',
                                }}/> 0.34%</span></>, value: '260,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
                </div>

                {/* 人才基本信息汇总 */}
                <div style={{position: 'absolute', left: '930px', top: '300px',}}>
                    <div className={'人才基本信息汇总'}
                         style={{width: '930px', height: '400px', pointerEvents: 'none',}}>
                        <div className={'background_image'} style={{width: '100%', height: '100%',}}/>
                        <div className={'target_box'} style={{width: '100%', height: '100%',}}>
                            <div className={'target'} style={{color: 'white'}}>2,800,000+</div>
                        </div>
                    </div>
                    <div>
                        {[
                            {title: '人才集团', left: '0px', top: '160px',},
                            {title: '人社', left: `${160 - 20}px`, top: '280px',},
                            {title: '就业', left: `${320 - 6}px`, top: '300px',},
                            {title: '教育', left: `${480 + 6}px`, top: '300px',},
                            {title: '医疗', left: `${640 + 20}px`, top: '280px',},
                            {title: '其他', left: '800px', top: '160px',},
                        ].map((it, index) => {
                            return <div key={index} className={'bubble'}
                                        style={{position: 'absolute', left: it.left, top: it.top,}}>
                                <div className={'title'}>{it.title}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div style={{position: 'absolute', left: '930px', top: '300px',}}>
                    <RedDotTitle title={'人才基本信息汇总'}/>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'}
                              bottom={'-30px'} left={'30px'}/>
                </div>

                {/* 服务企业 */}
                <div style={{position: 'absolute', left: '930px', top: '880px',}}>
                    <RedDotTitle title={'服务企业'}/>
                </div>
                <div style={{position: 'absolute', left: '930px', top: '920px',}}>
                    <Space
                        style={{
                            width: '450px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: <>企业服务</>, value: '6,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
                </div>

                {/* 服务各类人群 */}
                <div style={{position: 'absolute', left: '1410px', top: '880px',}}>
                    <RedDotTitle title={'服务各类人群'}/>
                </div>
                <div style={{position: 'absolute', left: '1410px', top: '920px',}}>
                    <Space
                        style={{
                            width: '450px', height: '110px',
                            display: 'flex', flexFlow: 'row',
                            overflow: 'hidden',
                        }}
                        styles={{item: {flex: 1,}}}
                    >
                        {[
                            {
                                title: <>服务各类人群</>, value: '5,800,000+',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: it.gradient,
                                    color: 'white',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '32px'}}>{it.value}</div>
                                <div style={{fontSize: '16px'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                    <FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>
                </div>
            </PageBox>
            <Modal
                title={isModalOpen[1]}
                open={isModalOpen[0] as boolean}
                onOk={hideModal}
                onCancel={hideModal}
                footer={[]}
                width={'75%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000'
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)'
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen[1]}`}
                    width="100%"
                    height="100%"
                    style={{overflow: 'hidden', border: 0}}
                    src={`${appBaseURL}/page_drill_1?region_name=${isModalOpen[1]}`}
                />
            </Modal>
        </>
    );
}

export default Page1;
