import React, {useEffect, useRef} from 'react';
import {Card, Space} from 'antd';
import * as echarts from 'echarts';
import {EChartsType} from "echarts";
import style from './PageDrill1.module.css';
import PageBox from "../../components/PageBox";
import RedDotTitle from "../../components/RedDotTitle";
import {useLocation} from "react-router-dom";
import DataStore from "./data/企业人才数.json";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import chart4 from "./components/chart4";

interface PageDrill1Props {
    width: number;
    height: number;
}

const PageDrill1: React.FC<PageDrill1Props> = (props) => {
    const {width, height} = props

    const location = useLocation();
    const region_name = (new URLSearchParams(location.search)).get('region_name') || '路北区';

    const data = DataStore.filter((it: any) => {
        return it.region === region_name
    })[0]

    let _Chart1: EChartsType
    const domRefChart1: any = useRef();
    let _Chart2: EChartsType
    const domRefChart2: any = useRef();
    let _Chart3: EChartsType
    const domRefChart3: any = useRef();
    let _Chart4: EChartsType
    const domRefChart4: any = useRef();
    const chartsInit = () => {
        if (_Chart1) _Chart1.dispose()
        _Chart1 = echarts.init(domRefChart1.current, 'vintage');
        _Chart1.setOption(chart1(region_name));

        if (_Chart2) _Chart2.dispose()
        _Chart2 = echarts.init(domRefChart2.current, 'vintage');
        _Chart2.setOption(chart2(region_name));

        if (_Chart3) _Chart3.dispose()
        _Chart3 = echarts.init(domRefChart3.current, 'vintage');
        _Chart3.setOption(chart3(region_name));

        if (_Chart4) _Chart4.dispose()
        _Chart4 = echarts.init(domRefChart4.current, 'vintage');
        _Chart4.setOption(chart4(region_name));
    };
    useEffect(() => {
        chartsInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageBox width={width} height={height}>
            <div className={style.background}></div>

            <div style={{
                position: 'absolute', left: '1053px',
                top: '488px',
                width: '261px', aspectRatio: 1, opacity: 0.3,
            }}>
                <div className={style.pic1}/>
            </div>

            {/* 汇总指标 */}
            <div style={{position: 'absolute', left: '55px', top: '55px',}}>
                <RedDotTitle title={`${region_name}`}/>
            </div>
            {/* 汇总指标 */}
            <div style={{position: 'absolute', left: '55px', top: '95px',}}>
                <Space
                    style={{
                        width: '545px', height: '110px',
                        display: 'flex', flexFlow: 'row',
                        overflow: 'hidden',
                    }}
                    styles={{item: {flex: 1,}}}
                >
                    {[
                        {
                            title: '企业数', value: data['企业数'].toLocaleString(),
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.6))',
                        },
                        {
                            title: '人才数', value: data['人才数'].toLocaleString(),
                            gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.3), rgba(23, 95, 190, 0.3))',
                        },
                    ].map((it, index) => (
                        <Card
                            key={index}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                background: it.gradient,
                                color: 'white',
                            }}
                            bodyStyle={{padding: '8px'}}
                            bordered={false}
                        >
                            <div style={{fontSize: '32px'}}>{it.value}</div>
                            <div style={{fontSize: '16px'}}>{it.title}</div>
                        </Card>
                    ))}
                </Space>
                {/*<FootNote title={'数据来源：人才数字平台'} link={'https://szpt.tsrcw.com/comm/Wad_web'} right={'0'}/>*/}
            </div>

            {/* 学历占比 */}
            <div style={{position: 'absolute', left: '55px', top: '240px',}}>
                <RedDotTitle title={'学历占比'}/>
            </div>
            <div style={{position: 'absolute', left: '95px', top: '220px',}}>
                <div ref={domRefChart3} style={{width: '640px', height: '400px',}}/>
            </div>

            {/* 人才存量 */}
            <div style={{position: 'absolute', left: '755px', top: '55px',}}>
                <RedDotTitle title={'人才存量'}/>
            </div>
            <div style={{position: 'absolute', left: '755px', top: '65px',}}>
                <div ref={domRefChart1} style={{width: '545px', height: '300px',}}/>
            </div>

            {/* 产业人才 */}
            <div style={{position: 'absolute', left: '755px', top: '340px',}}>
                <RedDotTitle title={'产业人才'}/>
            </div>
            <div style={{position: 'absolute', left: '755px', top: '350px',}}>
                <div ref={domRefChart2} style={{width: '545px', height: '300px',}}/>
            </div>

            {/* 行业企业数量 */}
            <div style={{position: 'absolute', left: '55px', top: '580px',}}>
                <RedDotTitle title={'行业企业数量'}/>
            </div>
            <div style={{position: 'absolute', left: '55px', top: '580px',}}>
                <div ref={domRefChart4} style={{width: '1200px', height: '260px',}}/>
            </div>
        </PageBox>
    );
}

export default PageDrill1;
