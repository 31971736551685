const chart2 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 0.8)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: -25,
            },
            data: [
                "不限",
                "初中及以下",
                "中专/中技",
                "高中",
                "大专",
                "本科",
                "硕士",
                "博士"
            ]
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                }
            },
        },
        series: [
            {
                type: 'bar',
                barWidth: 18,
                yAxisIndex: 0,
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                },
                data: [
                    0,
                    210,
                    791,
                    209,
                    3039,
                    3701,
                    407,
                    7
                ],
            }
        ]
    };
};
export default chart2
